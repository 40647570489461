import moment from 'moment'
import React from 'react'
import {
    TableRow,
    TableHeaderCell,
    TableHeader,
    TableCell,
    TableBody,
    Table,
    Label,
} from 'semantic-ui-react'
const _ = require('underscore');

interface props {
    records: any
}
const MySQLHealthTable = (props: props) => {
    const { records } = props;
    return (
        <>
            <Table striped compact='very'>
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell>Brand</TableHeaderCell>
                        <TableHeaderCell>Practice</TableHeaderCell>
                        <TableHeaderCell>DB Name</TableHeaderCell>
                        <TableHeaderCell>Event Schema</TableHeaderCell>
                        <TableHeaderCell>Status</TableHeaderCell>
                        <TableHeaderCell>Last Executed</TableHeaderCell>
                        <TableHeaderCell></TableHeaderCell>
                    </TableRow>
                </TableHeader>

                <TableBody>
                    {
                        records && _.sortBy(records, (item: any) => [item.eventDetails, item.practiceDBName]).map((logs: any, index: number) => {
                            const details = logs?.eventDetails;
                            const udaInfo = logs?.UdaPracticeInfo;
                            return <TableRow key={index}>
                                <TableCell>{udaInfo?.brand || '-'}</TableCell>
                                <TableCell>{udaInfo?.practiceName || '-'}</TableCell>
                                <TableCell>{logs?.practiceDBName || '-'}</TableCell>
                                <TableCell>{details?.EVENT_SCHEMA || '-'}</TableCell>
                                <TableCell>{udaInfo?.status || '-'}</TableCell>
                              
                                <TableCell>{moment(details?.LAST_EXECUTED).format('ll') || '-'}</TableCell>
                                <TableCell width={3}>{details ?
                                    <Label style={{ width: '100%' }} size='massive' color='green' horizontal></Label> :
                                    <Label style={{ width: '100%' }} size='massive' color='red' horizontal></Label>}
                                </TableCell>
                            </TableRow>
                        })
                    }
                </TableBody>
            </Table>
        </>
    )
}

export default MySQLHealthTable