import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Template from "./Template";
import { User } from "../model/user";
import { RootState } from "../model";
import { Practice } from "../model/practice";
import { useActions } from "../redux/actions";
import * as usersActions from "../redux/actions/users";
import { Checkbox, Dropdown, Form, Grid } from "semantic-ui-react";
import OpenDentalUserResult from "./OpenDentalUserResult";
import Loading from "../Loading";

interface Props {
  auth: User;
  practice: Practice;
  loader: any;
}

function OpenDentalUsers(props: Props) {
  const UsersActions = useActions(usersActions);
  const [result, setResult] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [selectedUserName, setSelectedUserName] = useState("");
  const [selectedUserDetails, setSelectedUserDetails] = useState({});
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [hidden, setHidden] = useState("true");
  const [listHidden, setListHidden] = useState(false);
  const { loader } = props;

  useEffect(() => {
    loadData(listHidden);
  }, []);

  const loadData = (hidden: boolean) => {
    const obj = {
      includeHidden: hidden,
    };
    UsersActions.getOdUserList(obj).then((result: any) => {
      const userMap: any = {};

      for (const location in result) {
        result[location].forEach((user: any) => {
          const normalizedUserName = user.UserName.toLowerCase(); // Normalizing for userMap

          // Consolidate by normalized UserName, but keep original UserName in user details
          if (!userMap[normalizedUserName]) {
            userMap[normalizedUserName] = {
              UserName: user.UserName, // Keep the original name for the dropdown
              IsHidden: user.IsHidden,
              userDetails: [],
            };
          }

          userMap[normalizedUserName].userDetails.push({
            UserNum: user.UserNum,
            location: location,
          });
        });
      }


      const userOptions: any = Object.keys(userMap).map((userName) => ({
        text: userMap[userName].UserName,
        value: userMap[userName].UserName, // Use UserName as value now
        key: userMap[userName].UserName,
        IsHidden: userMap[userName].IsHidden,
        userDetails: userMap[userName].userDetails, // Store all details per user
      }));

      const locationOptions: any = Object.keys(result).map((location) => ({
        text: location,
        value: location,
        key: location,
      }));

      setUserOptions(userOptions);
      setLocationOptions(locationOptions);

      if (userOptions.length > 0) {
        const defaultUser = userOptions[0];
        setSelectedUserName(defaultUser.value);
        setSelectedLocations(defaultUser.userDetails.map((d: any) => d.location));
        setHidden(defaultUser.IsHidden);
      }
    });
  };

  const handleUserChange = (e: any, { value }: any) => {
    setSelectedUserName(value);
    // Find the selected user and retrieve associated details
    const selectedUser: any = userOptions.find(
        (user: any) => user.value === value
    );

    // Extract user details, including UserNum and locations
    const userDetails = selectedUser.userDetails;
    const userNumList = userDetails.map((detail: any) => detail.UserNum); // Get all UserNums
    const locations = userDetails.map((detail: any) => detail.location); // Get all locations

    setSelectedUserDetails(userDetails);
    setSelectedLocations(locations); // Set all locations
    setHidden(selectedUser.IsHidden); // Set hidden status
  };


  const handleLocationChange = (e: any, { value }: any) => {
    setSelectedLocations(value);
  };

  const handleStatusChange = (e: any, { checked }: any) => {
    setHidden(checked ? "true" : "false");
  };

  const handleListHiddenUsers = (e: any, { checked }: any) => {
    setListHidden(checked);
    loadData(checked);
  };

  const handleSubmit = async () => {

    const obj = {
      userName: selectedUserName,
      details: selectedUserDetails,
      IsHidden: hidden,
    };
    const result = await UsersActions.updateOpenDentalUser(obj);
    setResult(result);
  };

  return (
      <Template activeLink="usage-spot">
        <div className="ui card">
          <div className="content">
            <h2 className="float-left mr10">Open Dental Users</h2>
          </div>
        </div>

        <Grid>
          <Grid.Row>
            <Grid.Column computer={8} tablet={16} mobile={16}>
              <div className="ui card">
                <div className="content">
                  <Form onSubmit={handleSubmit}>
                    <Form.Field>
                      <Checkbox
                          label="List Hidden Users"
                          checked={listHidden}
                          onChange={handleListHiddenUsers}
                      />
                    </Form.Field>
                    <div className="mb15">
                      <label>User: </label>
                      <Dropdown
                          placeholder="Select User"
                          fluid={true}
                          search={true}
                          selection={true}
                          options={userOptions}
                          value={selectedUserName}
                          onChange={handleUserChange}
                      />
                    </div>
                    <div className="mb15">
                      <label>Locations: </label>
                      <Dropdown
                          placeholder="Select Locations"
                          fluid={true}
                          multiple={true}
                          selection={true}
                          options={locationOptions}
                          value={selectedLocations}
                          onChange={handleLocationChange}
                      />
                    </div>
                    <Form.Field>
                      <Checkbox
                          label="Disable"
                          checked={hidden === "true"}
                          onChange={handleStatusChange}
                      />
                    </Form.Field>

                    <Form.Button>Submit</Form.Button>
                  </Form>
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {result && result.length ? (
            <OpenDentalUserResult
                result={result}
                message={"User has been updated successfully!"}
                handleClose={() => setResult([])}
            />
        ) : (
            ""
        )}
        {loader && <Loading />}
      </Template>
  );
}

function mapStateToProps(state: RootState) {
  return {
    auth: state.auth,
    practice: state.practice,
    loader: state.app.loader,
  };
}

export default connect(mapStateToProps)(OpenDentalUsers);
