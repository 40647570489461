import React, { Fragment, useEffect, useState } from 'react'
import { TableRow, TableHeaderCell, TableHeader, TableCell, TableBody, Table } from 'semantic-ui-react';
import { dentaTableFields, excludedStatuses, group, groupCount } from './healthStatusUtils';
import ViewPatientValidationData from './ViewPatientValidationData';

const _ = require('underscore');

interface props {
    data: any[],
}

const DentaHubChartTable = ({ data }: props) => {
    const filteredData = _.sortBy(data, (item: any) => [item.brand, item.practice]);
    const initialState = { view: false, patients: { practice: '', records: [] as any[] }, group: group };
    const [state, setState] = useState(initialState);
    const viewRecord = (view: boolean, info: any) => {
        setState({ ...state, view: view, patients: info })
    }

    const getStatus = (status: string, caption: string) => {
        switch (status) {
            case 'green':
                return { style: { background: '#00ff00', position: 'relative' }, caption: 'Scraped' }
            case 'olive':
                return { style: { background: '#93c47d', position: 'relative' }, caption: `No ${caption}` }
            case 'red':
                return { style: { background: '#ff0000', position: 'relative' }, caption: 'Failed' }
            default:
                return { style: { background: '#ffff00', position: 'relative' }, caption: '#N/A' }
        }
    }

    const getCountStats = (item: any, field: any) => {
        const patientValidation = item.patientCount;
        const patients: any[] = patientValidation[field.insurance] || [];
        if (!patients || patients.length < 0) return { failed: 0, total: 0 }
        const failed = patients.filter((patient: any) =>
            !excludedStatuses.includes(patient.status)
        ).length;

        if (field.scraper === 'PV')
            return <span className='scraper-view' onClick={() => viewRecord(true, { practice: item.name, records: patients })} >
                <strong><span style={{ color: 'red' }}>{failed}</span>/<span style={{ color: 'blue' }}>{patients.length}</span></strong>
            </span>
    }

    const getField = (item: any, field: any) => {
        const status = getStatus(item[field.name], field.caption);
        return <TableCell style={status.style}>{status.caption} {getCountStats(item, field)}</TableCell>
    }
    useEffect(() => {
        countAll();
    }, []);

    const countAll = () => {
        let count = {
            ...group
        }

        filteredData.forEach((practice: any) => {
            dentaTableFields.forEach((field: any) => {
                const patientValidation = practice.patientCount;
                const patients: any[] = patientValidation[field.insurance] || [];
                if (!patients || patients.length < 0) return { failed: 0, total: 0 }
                const failed = patients.filter((patient: any) =>
                    !excludedStatuses.includes(patient.status)
                ).length;
                count = groupCount(field.name, patients.length, failed);
            })
        });
        setState({ ...state, group: count })
    }

    return (
        <>
            <Table size='small' celled structured striped>
                <TableHeader>
                    <TableRow >
                        <TableHeaderCell colSpan='1' className='text-center'></TableHeaderCell>
                        <TableHeaderCell colSpan='4' className='text-center'>UHC</TableHeaderCell>
                        <TableHeaderCell colSpan='4' className='text-center'>Denta</TableHeaderCell>
                        <TableHeaderCell colSpan='4' className='text-center'>MCNA</TableHeaderCell>
                        <TableHeaderCell colSpan='3' className='text-center'>TMHP</TableHeaderCell>
                    </TableRow>
                    <TableRow>
                        <TableHeaderCell className='text-center sticky-subheader' width={1}>Practice</TableHeaderCell>
                        {
                            ["uhc", 'denta', 'mcna'].map((i , key) => {
                                return <Fragment key={key}>
                                    <TableHeaderCell className='text-center sticky-subheader' width={1}>Claims</TableHeaderCell>
                                    <TableHeaderCell className='text-center sticky-subheader' width={1}>Members</TableHeaderCell>
                                    <TableHeaderCell className='text-center sticky-subheader' width={1}>Patient V
                                        <span style={{ marginLeft: '5px' }}>
                                            <strong>
                                                <span style={{ color: 'red' }}>
                                                    {state.group[i].failed}</span>/
                                                <span style={{ color: 'blue' }}>
                                                    {state.group[i].total}
                                                </span>
                                            </strong>
                                        </span>
                                    </TableHeaderCell>
                                    <TableHeaderCell className='text-center sticky-subheader' width={1}>Spot</TableHeaderCell>
                                </Fragment>
                            })
                        }
                        <TableHeaderCell className='text-center sticky-subheader' width={1}>Claims</TableHeaderCell>
                        <TableHeaderCell className='text-center sticky-subheader' width={1}>Patient V
                            <span style={{ marginLeft: '5px' }}>
                                <strong>
                                    <span style={{ color: 'red' }}>
                                        {state.group.tmhp.failed}</span>/
                                    <span style={{ color: 'blue' }}>
                                        {state.group.tmhp.total}
                                    </span>
                                </strong>
                            </span>
                        </TableHeaderCell>
                        <TableHeaderCell className='text-center sticky-subheader' width={1}>Spot</TableHeaderCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {
                        data && filteredData.map((item: any, index: number) =>
                            <TableRow key={index}>
                                <TableCell className='text-center' title={`Abbr: ${item.abbr} \nBrand: ${item.brand} \nDbName: ${item.dbName}`}>{index + 1}. {item.name}</TableCell>
                                {dentaTableFields.map(field => getField(item, field))}
                            </TableRow>
                        )
                    }
                </TableBody>
            </Table>

            {state.view && <ViewPatientValidationData data={state.patients} show={state.view} onClick={(show) => viewRecord(show, {})} />}
        </>
    )
}

export default DentaHubChartTable