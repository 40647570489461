import React, { useState } from 'react'
import { TableRow, TableCell, TableBody, Table, Icon, Label, Popup } from 'semantic-ui-react'
import ProgressBar from '../ProgressBar'
import _ from 'lodash'
import { getStatus } from '../healthStatusUtils'

interface props {
    data: any[]
}

const PatientValidTable = ({ data }: props) => {
    const [state, setState] = useState({ selected: { mco: '', tab: '' } })
    const opts:any []= [
        { name: 'filesNotPushed', label: 'Files Not Pushed', count: 'fail', color: 'red' },
        { name: 'filesNotMatch', label: 'Files date not matching', count: 'wrongDate', color: 'blue' },
        { name: 'blankFiles', label: 'Files blank', count: 'blank', color: 'yellow' },
        { name: 'filesPushed', label: 'Files pushed', count: 'success', color: 'green' }];

    const { selected } = state;

    const onTileSelect = (mco: string, tab: string) => {
        if (!tab && selected.mco === mco) {
            setState({ ...state, selected: { ...selected, mco: '' } });
        } else if (selected.mco === mco && selected.tab === tab) {
            setState({ ...state, selected: { ...selected, tab: '' } });
        } else {
            setState({ ...state, selected: { mco, tab } });
        }
    }

    const getData = (mco: string, tab: string): any[] => {
        const info = data.find(d => d.name === mco)
        if (!info) return []
        return info[tab];
    }

    const getFileStatusIcon = (file:any) => {

        const filePushed = file?.filePushed;
        const filePushError =  file?.filePushError;

        switch (filePushed) {
            case 1:
                return <Icon name='file image' color='green' />;
            case 0:
                return (
                    <Popup
                        trigger={<Icon name='file excel' color='red' />}
                        content={filePushError ? filePushError : 'No error message yet!'}
                        basic={true}
                    />
                );
            case 6:
                return (
                    <Popup
                        trigger={<Icon name='file excel' color='blue' />}
                        content={filePushError ? filePushError : 'File dates do not match!'}
                        basic={true}
                    />
                );
                default:
                    return <Icon name='file image' color='yellow' />
        }
    };


    return (
        <>
            {
                data.map((mco, index) =>
                    <Table compact="very" fixed key={index} >
                        <TableBody key={index}>
                            <TableRow key={index} onClick={() => onTileSelect(mco.name, '')} style={{ cursor: 'pointer' }}>

                                <TableCell colspan='9'><strong style={{ marginLeft: '20px' }}><Icon name='dropdown' />{mco.name} <small>({mco.total})</small></strong></TableCell>
                                <TableCell>
                                    <ProgressBar success={mco.total - mco.fail} pending={mco.blank} failed={mco.fail} />
                                </TableCell>
                            </TableRow>
                            {
                                state.selected.mco === mco.name && opts.map(({ color, count, label, name }, key: number) => (
                                    <>
                                        <TableRow onClick={() => onTileSelect(mco.name, name)} style={{ cursor: 'pointer', backgroundColor: key % 2 == 0 ? '#eaeaea' : 'white' }} key={key + index}>
                                            <TableCell colspan='9'><strong style={{ marginLeft: '35px' }}>{label}  <small>({mco[count]})</small></strong></TableCell>
                                            <TableCell><Label style={{ display: 'block', width: '100%', height: '100%' }} color={color} key={key}></Label></TableCell>
                                        </TableRow>

                                        {selected.mco === mco.name && name === selected.tab &&
                                            getData(mco.name, name).map((o, i) =>
                                            (
                                                <TableRow key={i}>
                                                    <TableCell></TableCell>
                                                    <TableCell> <strong>Status: </strong> {getStatus(o)}</TableCell>
                                                    <TableCell><strong>File Status: </strong>{getFileStatusIcon(o)}</TableCell>
                                                    <TableCell> <strong>Practice: </strong> {o.practice}</TableCell>
                                                    <TableCell><strong>PatNum: </strong>{o.PatNum}</TableCell>
                                                    <TableCell><strong>Apt_Date: </strong>{o.Apt_Date}</TableCell>
                                                    <TableCell><strong>APT_Status: </strong>{o.APT_Status}</TableCell>
                                                    <TableCell colspan='2'><strong>CarrierName: </strong>{o.CarrierName}</TableCell>
                                                    <TableCell><strong>SubscriberID: </strong>{o.SubscriberID}</TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </>
                                ))
                            }
                        </TableBody>
                    </Table>
                )
            }
        </>
    )
}

export default PatientValidTable