import * as React from 'react';
import {Bar} from 'react-chartjs-2';
import {Card, Grid} from "semantic-ui-react";
import { nFormatter } from './nFormatter';

export interface PMDScheduleChartProps {
  practicename: string,
  aptfill_today: any,
  aptfill_yesterday: any,
  aptfill_twoDaysAgo: any,
  FV_PT_today: any,
  FV_PT_yesterday: any,
  FV_PT_twoDaysAgo: any,
  TX_PT_today: any,
  TX_PT_yesterday: any,
  TX_PT_twoDaysAgo: any,
  RC_PT_today: any,
  RC_PT_yesterday: any,
  RC_PT_twoDaysAgo: any,
  ptSeenToday: any,
  Shifts: any,
  scheduleToday30DayApts:any,
  scheduleToday30DayCompletd:any,
  pmdPtSeenTodayDetail:any [],
  general_shifts:any [],
  hygienists_shifts:any []
}

export interface PMDScheduleChartState {
  practicename: string,
  aptfill_today: any,
  aptfill_yesterday: any,
  aptfill_twoDaysAgo: any,
  FV_PT_today: any,
  FV_PT_yesterday: any,
  FV_PT_twoDaysAgo: any,
  TX_PT_today: any,
  TX_PT_yesterday: any,
  TX_PT_twoDaysAgo: any,
  RC_PT_today: any,
  RC_PT_yesterday: any,
  RC_PT_twoDaysAgo: any,
  ptSeenToday: any,
  Shifts: any,
  scheduleToday30DayApts:any,
  scheduleToday30DayCompletd:any,
  pmdPtSeenTodayDetail:any [],
  general_shifts:any [],
  hygienists_shifts:any []
}

class PMDScheduleChart extends React.Component<PMDScheduleChartProps,PMDScheduleChartState> {  
  constructor(props: PMDScheduleChartProps) {
    super(props);
    this.state={
      practicename : props.practicename,
      aptfill_today: props.aptfill_today,
      aptfill_yesterday: props.aptfill_yesterday,
      aptfill_twoDaysAgo: props.aptfill_twoDaysAgo,
      FV_PT_today: props.FV_PT_today,
      FV_PT_yesterday: props.FV_PT_yesterday,
      FV_PT_twoDaysAgo: props.FV_PT_twoDaysAgo,
      TX_PT_today: props.TX_PT_today,
      TX_PT_yesterday: props.TX_PT_yesterday,
      TX_PT_twoDaysAgo: props.TX_PT_twoDaysAgo,
      RC_PT_today: props.RC_PT_today,
      RC_PT_yesterday: props.RC_PT_yesterday,
      RC_PT_twoDaysAgo: props.RC_PT_twoDaysAgo,
      ptSeenToday: props.ptSeenToday,
      Shifts: props.Shifts,
      scheduleToday30DayApts: props.scheduleToday30DayApts,
      scheduleToday30DayCompletd: props.scheduleToday30DayCompletd,
      pmdPtSeenTodayDetail: props.pmdPtSeenTodayDetail,
      general_shifts: props.general_shifts,
      hygienists_shifts: props.hygienists_shifts,
    }
    this.setPracticeName=this.setPracticeName.bind(this);
  }

  setPracticeName(value: string) {
    this.setState({
      practicename : value
    })
  }

  componentWillReceiveProps(nextProps: PMDScheduleChartProps) {
    this.setState({
      practicename: nextProps.practicename,
      aptfill_today: nextProps.aptfill_today,
      aptfill_yesterday: nextProps.aptfill_yesterday,
      aptfill_twoDaysAgo: nextProps.aptfill_twoDaysAgo,
      FV_PT_today: nextProps.FV_PT_today,
      FV_PT_yesterday: nextProps.FV_PT_yesterday,
      FV_PT_twoDaysAgo: nextProps.FV_PT_twoDaysAgo,
      TX_PT_today: nextProps.TX_PT_today,
      TX_PT_yesterday: nextProps.TX_PT_yesterday,
      TX_PT_twoDaysAgo: nextProps.TX_PT_twoDaysAgo,
      RC_PT_today: nextProps.RC_PT_today,
      RC_PT_yesterday: nextProps.RC_PT_yesterday,
      RC_PT_twoDaysAgo: nextProps.RC_PT_twoDaysAgo,
      ptSeenToday: nextProps.ptSeenToday,
      Shifts: nextProps.Shifts,
      scheduleToday30DayApts: nextProps.scheduleToday30DayApts,
      scheduleToday30DayCompletd: nextProps.scheduleToday30DayCompletd,
      pmdPtSeenTodayDetail: nextProps.pmdPtSeenTodayDetail,
      general_shifts: nextProps.general_shifts,
      hygienists_shifts: nextProps.hygienists_shifts,
    })
  }

  getPTGoal():any {
    let ptVisitTotal:any = this.props.FV_PT_today+this.props.RC_PT_today+this.props.TX_PT_today;
    let ptVisitPerShift = ptVisitTotal/this.props.Shifts;
    const today = new Date();
    const index = today.getDate();
    let todaysShifts:any =  this.getItemAtIndex(this.props.general_shifts,index)+this.getItemAtIndex(this.props.hygienists_shifts,index);
    let ptGoal:any = todaysShifts*ptVisitPerShift;
    return Math.ceil(ptGoal);   
  };

  getItemAtIndex(data: any[], index: number){
    if (data && data.length > index && index >= 0) {
      return data[index];
    }
    return 0;
  };

  getShowRate():any {
    let show_rate = this.props.scheduleToday30DayCompletd/this.props.scheduleToday30DayApts;
    return show_rate;
  };  

options  = () => {
    const minValue = Math.min(
        Math.floor(this.getShowRate() * this.props.aptfill_today),
        Math.floor(this.getShowRate() * this.props.aptfill_yesterday),
        Math.floor(this.getShowRate() * this.props.aptfill_twoDaysAgo)
    ) - 3;
    const options_config = {
        scales: {
            xAxes: [{
                gridLines: {display: true},
                ticks: {beginAtZero: true},
            }],
            yAxes: [{
                gridLines: {display: false},
                ticks: {
                  min: minValue < 0 ? 0 : minValue,
                  callback: nFormatter},
            }]            
        },
        responsive: false,
        maintainAspectRatio: false,
    };
    return options_config;
}

  getNextThreeWorkDays() {
    const today = new Date();
    const startIndex = today.getDate();
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const labels: string[] = [];
    const aptFillData: number[] = [];
    const ptGoalData: number[] = [];

    let count = 0;
    for (let i = startIndex; i < this.props.general_shifts.length && count < 3; i++) {
      if (this.props.general_shifts[i] !== null) {
        const date = new Date(today);
        date.setDate(i);
        labels.push(`${days[date.getDay()]}-${date.getMonth() + 1}/${date.getDate()}`);

        if (count === 0) {
          aptFillData.push(Math.floor(this.getShowRate() * this.props.aptfill_today));
          ptGoalData.push(this.getPTGoal());
        } else if (count === 1) {
          aptFillData.push(Math.floor(this.getShowRate() * this.props.aptfill_yesterday));
          ptGoalData.push(this.getPTGoal());
        } else if (count === 2) {
          aptFillData.push(Math.floor(this.getShowRate() * this.props.aptfill_twoDaysAgo));
          ptGoalData.push(this.getPTGoal());
        }

        count++;
      }
    }

    return { labels, aptFillData, ptGoalData };
  }

  data = () => {
    const { labels, aptFillData, ptGoalData } = this.getNextThreeWorkDays();

    return {
      labels: labels,
      datasets: [
        {
          type: 'line',
          label: 'PT Goal',
          data: ptGoalData,
          backgroundColor: 'rgb(255, 0, 0)',
          borderColor: 'rgb(255, 0, 0)',
          fill: false,
          borderWidth: 2,
        },
        {
          type: 'bar',
          label: 'Apt Fill',
          data: aptFillData,
          backgroundColor: 'rgb(75, 192, 192)',
          borderColor: 'rgb(75, 192, 192)',
        },
      ],
    };
  };

  render() {
    return (
        <Grid>
        <Grid.Row>
            <Grid.Column width={8}>
                <Card>
                    <Card.Content>
                    <Bar data={this.data()} options={this.options()} />
                    </Card.Content>
                </Card>
            </Grid.Column>
        </Grid.Row>
        </Grid>
)
  }
}

export default PMDScheduleChart;