import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import Template from "./Template";
import {RootState} from "../model";
import {setPractices} from "../utils/common";
import {useActions} from "../redux/actions";
import * as practiceActions from "../redux/actions/practice"
import * as openDentalActions from "../redux/actions/openDental";
import {Button, Dropdown, Form, Grid, Input, Message} from "semantic-ui-react";
import {Account} from "../model/user";


interface Props {
    user: Account
}

function InsuranceOpenDental(props: Props) {

    const practiceAction = useActions(practiceActions);
    const openDentalAction = useActions(openDentalActions);

    const initialState: any = {
        practiceOpts: [],
        success: false
    };

    const initialForm: any = {
        file: null,
        practice: null,
        patNum: null
    };

    const [state, setState] = useState(initialState);
    const [inputData, setInputData] = useState(initialForm);
    const {user} = props;


    useEffect(() => {
        practiceAction.fetchUdaPractices().then((practices: any) => {
            const practiceOpts = setPractices(practices, true)
            setState({...state, practiceOpts: practiceOpts})
        })
    }, []);

    const handleFileChange = (event: any) => {
        setInputData({...inputData, file: event.target.files[0]});
    };

    const handleSubmit = () => {
        const formData = new FormData();
        formData.append('file', inputData.file);
        formData.append('practice', inputData.practice);
        formData.append('patNum', inputData.patNum);
        openDentalAction.uploadInsurance(formData).then(() => {
            setState({...state, success: true})
        }).catch((err: any) => {
            setState({...state, success: false})
        });
    }

    return (
        <Template activeLink='open-dental-insurance'>
            {state.success && <Message
                onDismiss={() => setState({...state, success: false})}
                color='green'
                content={'File has been uploaded successfully!'}
            />}
            <div className="ui card">
                <div className="content">
                    <h2 className="float-left mr10">Open Dental Insurance Push</h2>


                </div>
            </div>
            {(user && user.role &&
                (user.role.indexOf('PPO_file_upload') >= 0)) ?
                <div className="ui card">
                    <div className="content">
                        <Grid columns={3} divided={true}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Form>
                                        <Form.Field>
                                            <label>Practice</label>
                                            <Dropdown
                                                placeholder='Select Practice'
                                                options={state.practiceOpts}
                                                selection={true}
                                                search={true}
                                                onChange={(e, values) => setInputData({
                                                    ...inputData,
                                                    practice: values.value
                                                })}
                                                name="practice"
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>PatNum</label>
                                            <Input
                                                placeholder='PatNum'
                                                onBlur={(e: any) => setInputData({
                                                    ...inputData,
                                                    patNum: e.target.value
                                                })}
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <input type="file" onChange={handleFileChange}/>
                                        </Form.Field>
                                        <Button onClick={() => handleSubmit()}>Submit</Button>
                                    </Form>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                </div>
                :
                <div className="ui card">
                    <div className="content">
                        You are not authorized to access this page, Please contact administrator.
                    </div>
                </div>
            }


        </Template>
    );
}

function mapStateToProps(state: RootState) {
    return {
        user: state.auth.user
    };
}

export default connect(mapStateToProps)(InsuranceOpenDental)