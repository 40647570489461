import React, {useEffect, useState} from 'react';
import {Dropdown, Input} from "semantic-ui-react";
import * as patientValidationActions from "../redux/actions/patients";
import {aptStatusOptions, enabledCarrierList, statusOptions,brands, filePushedOptions} from "../constants";
import {useActions} from "../redux/actions";
import {RootState} from "../model";
import {connect} from "react-redux";
import {PTValidationFilters} from "../model/patients";
import { findPractice } from '../utils/common';

function PatientValidationFilters(props: any) {
  const patientValidationAction = useActions(patientValidationActions);
  const initialState: PTValidationFilters = {
    locations: props.filters?.locations?.length > 0 ? props.filters.locations : [],
    insurance: props.filters?.insurance?.length ? props.filters.insurance : enabledCarrierList,
    keyword : props.keyword ? props.keyword : '',
    practices: props.filters?.practices?.length > 0 ? props.filters.practices : [],
    status: props.filters.status ? props.filters.status : 'all',
    aptStatus : props.filters.aptStatus ? props.filters.aptStatus : 'all',
    insuranceCategory:  props.filters?.insuranceCategory?.length > 0 ? props.filters.insuranceCategory : [],
    brand: props.filters?.brand?.length > 0 ? props.filters.brand : ['All'],
    filePushed: props.filters.filePushed ? props.filters.filePushed : 'all'
  }
  const [state, setStates] = useState(initialState);
  const insuranceCategoryOptions: Array<object> = [];
  enabledCarrierList && enabledCarrierList.forEach((item: any, index: number) => {
    insuranceCategoryOptions.push(
      { text: item, value: item, key: index }
    )
  })

  useEffect(() => {
    let practiceObj = props.patients.defaultPractice;
    let locations = props.practice.practiceNames;
    let practices = [];
      if (practiceObj) {
        if (practiceObj.value !== 'all') {
          const practiceOptions = practiceObj.value.split(',');
          let defaultPractices:any =[]
          practiceOptions.forEach((practiceOption:any)=>{
            if(practiceOption !== 'all'){
              const practice = findPractice(props.practice.practiceList, practiceOption) || {practice: 'all'};
              if(practice.practice !== 'all'){
                defaultPractices.push(practice.practice)
              }
              
            }
            
          });
          
          if(props.filters.practices.length == 0){
            locations = defaultPractices.length>0?defaultPractices:props.practice.practiceNames;
            practices =  defaultPractices.length>0?defaultPractices:['all'];
          } else {
            locations = props.filters.practices;
            practices = props.filters.practices;
          }
        }
      }
    setStates({...state, locations: locations, practices: practices})
  }, [props.patients.defaultPractice])
  
  const onSearchKeyword = (e: any, data: any) => {
    setStates({ ...state, keyword: data.value.trim() })
  }
  
  const onChangeLocation = (e: any, element: any) => {
    const values = element.value;
    const practiceNames = props.practice.practiceNames;
    let locations = values;
    let practices = values
    if (values.length >= 0 && values.indexOf('all') === values.length -1) {
      locations = practiceNames;
      practices = ['all'];
    } else if (values.length > 1 && values.indexOf('all') === 0) {
      values.splice(0, 1);
      locations = values;
      practices = values;
    }
    const filters = {
      locations: locations,
      practices: practices,
      status: state.status,
      insurance: state.insurance,
      keyword: '',
      aptStatus : state.aptStatus,
      filePushed: state.filePushed,
    }
    patientValidationAction.onFiltersChange(filters)
    props.onFilterChange(filters.aptStatus, filters.status, filters.practices, false, filters.filePushed, filters.insurance);
    setStates({...state, practices: practices, aptStatus: filters.aptStatus, locations: locations})
  };
  const onChangeBand = (e: any, element: any) => {
    let brand = element.value;
    if(brand.length>1&&brand.includes("All")){
      brand=brand.filter((ele:string)=>ele!=="All")
    }else{
      if(brand.length==0){
        brand=["All"]
      }
    }
    setStates({...state, brand: brand})
    const filters = {
      locations: state.locations,
      practices: state.practices,
      status: state.status,
      insurance: state.insurance,
      keyword: '',
      aptStatus :state.aptStatus ,
      brand:brand.filter((ele:string)=>ele!=="All"),
      filePushed: state.filePushed,
    }
    patientValidationAction.onFiltersChange(filters);
    props.onFilterChange(filters.aptStatus, filters.status, filters.practices, false, filters.filePushed, filters.insurance);

  }

  const onChangeAptStatus = (e: any, element: any) => {
    const filters = {
      locations: state.locations,
      practices: state.practices,
      status: state.status,
      insurance: state.insurance,
      keyword: '',
      brand:state.brand,
      aptStatus : element.value,
      filePushed: state.filePushed
    }
    patientValidationAction.onFiltersChange(filters);
    props.onFilterChange(filters.aptStatus, filters.status, filters.practices, false, filters.filePushed, filters.insurance);
    setStates({...state, aptStatus: element.value})
  }


  const onChangeInsurance = (e: any, element: any) => {
    const filters = {
      locations: state.locations,
      practices: state.practices,
      status: state.status,
      insurance: element.value,
      keyword: '',
      aptStatus : state.aptStatus,
      brand:state.brand,
      filePushed: state.filePushed,
    }
    patientValidationAction.onFiltersChange(filters);
    props.onFilterChange(filters.aptStatus, filters.status, filters.practices,true,filters.filePushed, filters.insurance);
    setStates({...state, insurance: element.value})
  }
  
  const onChangeStatus = (e: any, element: any) => {
    props.onFilterChange({
      locations: state.locations,
      practices: state.practices,
      insurance: state.insurance,
      status: element.value,
      aptStatus : state.aptStatus,
      brand:state.brand,
      filePushed: state.filePushed,
    })
    const filters = {
      locations: state.locations,
      practices: state.practices,
      insurance: state.insurance,
      status: element.value,
      keyword: '',
      aptStatus : state.aptStatus,
      brand:state.brand,
      filePushed: state.filePushed,
    }
    patientValidationAction.onFiltersChange(filters);
    props.onFilterChange(filters.aptStatus, filters.status, filters.practices,false,filters.filePushed, filters.insurance);
    setStates({...state, status: element.value})
  }

  const onChangeFileStatus = (e: any, element: any) => {
    const filters = {
      locations: state.locations,
      practices: state.practices,
      insurance: state.insurance,
      status: state.status,
      keyword: '',
      aptStatus : state.aptStatus,
      brand:state.brand,
      filePushed: element.value,
    }
    patientValidationAction.onFiltersChange(filters);
    props.onFilterChange(filters.aptStatus, filters.status, filters.practices,false,filters.filePushed, filters.insurance);
    setStates({...state, filePushed: element.value})
  }
  
  
  return (
    <div className={'topFilters'}>
      <Input
        className='mr10 mb15'
        name="keyword"
        placeholder="PatNum or SubID"
        onBlur={() => props.onSearch(state)}
        icon={{ name: 'search', link: true }}
        onChange={onSearchKeyword}
        value={state.keyword}
      />
      <Dropdown
          search={true}
          className='mr10 mb15'
          name="brand"
          placeholder="Band"
          multiple={true}
          selection={true}
          options={brands}
          onChange={onChangeBand}
          value={state.brand?state.brand:[]}
      />
      <Dropdown
          search={true}
          className='mr10 mb15'
          name="aptStatus"
          placeholder="APT Status"
          selection={true}
          options={aptStatusOptions}
          onChange={onChangeAptStatus}
          value={state.aptStatus}
      />
      <Dropdown
        search={true}
        className='mr10 mb15'
        name="locations"
        multiple={true}
        placeholder="Practice"
        selection={true}
        options={props.practice.practiceOptions.filter((item: any) => item.text !== 'Castleton' && item.text !== 'Castlseton' && item.text !== 'Southport')}
        onChange={onChangeLocation}
        value={state.practices ? state.practices : []}
      />
      <Dropdown
        search={true}
        className='mr10 mb15'
        name="insCategory"
        multiple={true}
        placeholder="Ins Category"
        selection={true}
        options={insuranceCategoryOptions}
        onChange={onChangeInsurance}
        value={state.insurance.length ? state.insurance : []}
      />
      <Dropdown
        search={true}
        className='mr10 mb15 validationStatus'
        name="status"
        placeholder="Status"
        selection={true}
        options={statusOptions}
        onChange={onChangeStatus}
        value={state.status}
      />
      <Dropdown
        search={true}
        className='mr10 mb15 validationStatus'
        name="filePushed"
        placeholder="File Pushed"
        selection={true}
        options={filePushedOptions}
        onChange={onChangeFileStatus}
        value={state.filePushed}
      />
    </div>
  )
}
function mapStateToProps(state: RootState) {
  return {
    practice: state.practice,
    patients: state.patients,
  };
}
export default connect(mapStateToProps)(PatientValidationFilters);