import _ from 'lodash';
import * as React from 'react';
import {Button, Label, Modal, Table} from 'semantic-ui-react'
import {v4 as uuidv4} from 'uuid';

export interface PMDScheduleTableProps {
  practicename: string,
  schedulePMDDataOptions: [],
  schedulesShowPopup : boolean
}

export interface PMDScheduleTableState {
  practicename: string,
  schedulePMDDataOptions: [],
  schedulesShowPopup : boolean
}

class PMDScheduleTable extends React.Component<PMDScheduleTableProps,PMDScheduleTableState> {  
  constructor(props: PMDScheduleTableProps) {
    super(props);
    this.state={
      practicename : props.practicename,
      schedulePMDDataOptions: props.schedulePMDDataOptions,
      schedulesShowPopup : props.schedulesShowPopup
    }
    this.setPracticeName=this.setPracticeName.bind(this);
    this.setOpen=this.setOpen.bind(this);
  }

  setPracticeName(value: string) {
    this.setState({
      practicename : value
    })
  }

  componentWillReceiveProps(nextProps: PMDScheduleTableProps) {
    this.setState({
      practicename: nextProps.practicename,
      schedulePMDDataOptions: nextProps.schedulePMDDataOptions,
      schedulesShowPopup: nextProps.schedulesShowPopup
    })
  }


  setOpen(value: boolean) {
    this.setState({
      schedulesShowPopup : value
    })
  }

  getDistinct(scheduleitems: any[]) {
    const notDeleted = scheduleitems.filter(item => !item.is_deleted);
    return Array.from(
        new Set(notDeleted.map(item => item.userId))
    ).map(userId => notDeleted.find(item => item.userId === userId));
  }

  render() {
    return (
    <Modal
      closeOnEscape={false}
      closeOnDimmerClick={false}
      open={this.state.schedulesShowPopup}
      onClose={() => this.setOpen(false)}
      onOpen={() => this.setOpen(true)}
      trigger={<div className="ui labeled button">
      <div className="ui basic blue button">
      <u className="pmd_numbers_clickable">Schedules</u>
      </div>
      <a className="ui basic white left pointing label lightorangebg">
        {this.getDistinct(this.props.schedulePMDDataOptions).length}
      </a>
    </div>
    }
    >
      <Modal.Header>Doctor Schedules
      <Label color={this.props.schedulePMDDataOptions.length>0?'green':'red'} floating>
            {this.getDistinct(this.props.schedulePMDDataOptions).length}
          </Label>
      <div className="ui top right attached label production_right_label">
        <Button onClick={() => this.setOpen(false)} inverted>
          Close
        </Button>
      </div>
      </Modal.Header>
      <Modal.Content >

      <Table className="table table-striped table-hover my-0 TableHeaderFixed">   
        <Table.Header>
        <Table.Row>
            <Table.HeaderCell><div className="tbl-header">Schedule</div></Table.HeaderCell>
            <Table.HeaderCell><div className="tbl-header">Dr Name</div></Table.HeaderCell>
        </Table.Row>
        </Table.Header>

        <Table.Body>
            {Object.values(_.sortBy(this.getDistinct(this.props.schedulePMDDataOptions),'schedule')).map(
            ({ role, userName, schedule}) => {
                return (
                    <Table.Row key={uuidv4()}>
                      <Table.Cell>{schedule}</Table.Cell>
                      <Table.Cell>{role} {userName}</Table.Cell>
                    </Table.Row>
                );
            }
            )}
        </Table.Body>
      </Table> 
      </Modal.Content>
      <Modal.Actions>
      </Modal.Actions>
    </Modal>


)
  }
}

export default PMDScheduleTable;