

export const typeOptions = [
    { key: 1, text: 'Medicaid', value: 'Medicaid' },
    { key: 2, text: 'PPO/Cash', value: 'PPO/Cash' },
    { key: 3, text: 'Both', value: 'Both' },
]

export const queueOptions = ['No', 'Yes'].map((queue, index) => { return { key: index, text: queue, value: queue } })
export const permissions = [
    { label: 'Administrator', value: 'admin' },
    { label: 'Members', value: 'members' },
    { label: 'Claims', value: 'claims' },
    { label: 'Dental CRM', value: 'dentalcrm' },
    { label: 'Excutive Care Metrics', value: 'exec_care_metrics' },
    { label: 'Educator', value: 'educator' },
    { label: ' DCRM Reports', value: 'dcrmreports' },
    { label: 'Schedule', value: 'schedule' },
    { label: 'Spot', value: 'spot' },
    { label: 'LEAP', value: 'leap' },
    { label: 'Leap Edit', value: 'leapedit' },
    { label: 'SPOT On', value: 'spoton' },
    { label: 'SPOT COMMLOG SEARCH', value: 'spotCommlogSearch' },
    { label: 'Spore', value: 'spore' },
    { label: 'SPOT - On Admin', value: 'spotonadmin' },
    { label: 'Call Center User', value: 'callcenter' },
    { label: 'CC Recordings', value: 'ccRecordings' },
    { label: 'UDA', value: 'uda' },
    { label: 'Recruitment', value: 'recruitment' },
    { label: 'Recruit Marketing', value: 'recruitMarketing' },
    { label: 'Credentialing Admin', value: 'adminCredentialing' },
    { label: 'Exclude Usage', value: 'excludeUsage' },
    { label: 'Dev Tools', value: 'devTools' },
    { label: 'Ortho PCR', value: 'orthoPCR' },
    { label: 'PV Run All', value: 'PVrunAll' },
    { label: 'Sites Create New Field', value: 'SitesCreateNewField' },
    { label: 'Re - Run Cron', value: 'reRunCron' },
    { label: 'Patient Finance', value: 'patientFinance' },
    { label: 'Patient Finance - Manager', value: 'patientFinanceManager' },
    { label: 'UKG', value: 'ukg' },
    { label: 'PPO - File Upload', value: 'PPO_file_upload' },
];
export const permissionsOptions = permissions.map((perms, index) => { return { key: perms.value, text: perms.label, value: perms.value } });

export const headers = [
    { label: 'Username/UkgId', name: 'username' },
    { label: 'Administrator', name: 'admin' },
    { label: 'Fullname', name: 'firstname' },
    { label: 'Email', name: 'email' },
    { label: 'Permissions', name: 'permissions' },
    { label: 'Practice', name: 'practice' },
    { label: 'Educator Manager', name: 'manager' },
    { label: 'Regional Manager', name: 'regionalmanager' },
    { label: 'Copy Manger', name: 'specialmanager' },
    { label: 'Queue', name: 'queue' },
    { label: 'Status', name: 'statuses' },
    { label: 'Type', name: 'types' },
    { label: 'Last Login', name: 'lastLogin' },
];

export const schedulePermissions = [
    {key:1,text:"Doctor", value:"Doctor"},
    {key:2,text:"Admin", value:"Admin"},
    {key:3, text:'View',value:"View"},
    {key:4 ,text: "Hygienists", value: "Hygienists"},
    {key:5 ,text: "Traveling Team", value: "Traveling Team"}
  ];


