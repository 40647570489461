import { combineReducers } from "redux";
import * as authReducer from "./user";
import * as alertReducer from "./alert";
import * as appReducer from "./app";
import * as practiceReducer from "./practice";
import * as partnerReducer from './partners';
import * as reportReducer from "./report";
import * as adjustmentReducer from "./adjustment";
import * as five9Reducer from "./five9List";
import * as mdmReducer from './mdm';
import * as patientsReducer from './patients'
import * as mdhmConfirmation from './mdhmConfirmation'
import * as sporeReducer from './spore'
import * as sporeNotificationsReducer from './sporeNotifications'
import * as pmdprogressReducer from './pmdprogress'
import * as educatorNotificationsReducer from './educatorNotifications'
import * as pmdtodaygoalallReducer from './pmdtodaygoalall'
import * as pmdTodaysGoallAllProgress from './pmdTodaysGoallAllProgress'
import * as procedureReducer from './procedureCodeChange'
import * as budgetReducer from './budget'
import * as referralReducer from './referrals'
import * as adjustmentCodesReducer from './adjustmentCodes'
import * as writeOffListReducer from './writeOffList'
import * as reportFilValReducer from './filterByValReducer'
import * as reportTimeAndName from './reportTimeAndName'
import * as messageReducer from './message';
import * as goxProcedureCodeReducer from './goxProcedureCode';
import * as providerAccountReducer from './providerAccounts';
import * as regionsReducer from "./regions";
import * as spotReducer from "./spot";
import * as countReducer from './healthStatusCount';
import * as patientValidationHealthReducer from "./healthStatus"
import * as tedCollectionsReducer from './tedCollections';

export default () =>
	combineReducers({
		...authReducer,
		...alertReducer,
		...appReducer,
		...practiceReducer,
		...partnerReducer,
		...reportReducer,
		...adjustmentReducer,
		...five9Reducer,
		...mdmReducer,
		...patientsReducer,
		...mdhmConfirmation,
		...sporeReducer,
		...sporeNotificationsReducer,
		...pmdprogressReducer,
		...educatorNotificationsReducer,
		...pmdtodaygoalallReducer,
		...pmdTodaysGoallAllProgress,
		...procedureReducer,
		...referralReducer,
		...adjustmentCodesReducer,
		...writeOffListReducer,
		...budgetReducer,
		...reportFilValReducer,
		...reportTimeAndName,
		...messageReducer,
		...goxProcedureCodeReducer,
		...providerAccountReducer,
		...regionsReducer,
		...spotReducer,
		...countReducer,
		...patientValidationHealthReducer,
		...tedCollectionsReducer
	});


