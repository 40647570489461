import _ from 'lodash';
import React from 'react'
import {
    TableRow,
    TableHeaderCell,
    TableHeader,
    TableCell,
    TableBody,
    Table,
    Label,
} from 'semantic-ui-react'

interface props {
    data: any
}
const XrayHealthTable = ({ data }: props) => {

    const getLastDate = (info: any) =>
       info?.daysLastRan < 1 ? 'TODAY' : info?.daysLastRan < 2 ? 'YESTERDAY' : `${info?.daysLastRan} DAYS ago`;


    return (
        <>
            <Table striped compact='very'>
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell>Practice</TableHeaderCell>
                        <TableHeaderCell>Last Active</TableHeaderCell>
                        <TableHeaderCell></TableHeaderCell>
                    </TableRow>
                </TableHeader>

                <TableBody>
                    {
                       _.sortBy(data , (item: any) => [item.status?.IsRunning, item?.directory || item.practice]).map((logs: any, index: number) =>
                            <TableRow key={index}>
                                <TableCell>{logs?.directory || logs.practice}</TableCell>
                                <TableCell>{getLastDate(logs.status)}</TableCell>
                                <TableCell textAlign='right' width={3}>{logs.status?.IsRunning ? <Label style={{ width: '70%' }} size='massive' color='green' horizontal></Label> :
                                    <Label style={{ width: '70%' }} size='massive' color='red' horizontal></Label>}
                                </TableCell>
                            </TableRow>
                        )
                    }
                </TableBody>
            </Table>
        </>

    )
}

export default XrayHealthTable