import {ActionType} from "../../model/actionTypes";
import {errorHandler} from "./auth";


export const loadLogin = (id?: number) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const params = id ? `/${id}` : '';
        return await api.get(`provider/login${params}`);
    } catch (err) {
        return dispatch(errorHandler(err));
    }
};


export const getProfiles = (data = {} as any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const query = Object.keys(data).map(key => `${key}=${data[key]}`).join('&');
        const res = await api.get(`provider/profiles?${query}`);
        dispatch({type: ActionType.LOADER, payload: false});
        return res
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        return dispatch(errorHandler(err));
    }
};

export const saveProfile = (data = {} as any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const res = await api.put(`provider/profiles/updateProfile`, data)
        dispatch({type: ActionType.LOADER, payload: false})
        return true
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        return dispatch(errorHandler(err));
    }
}

export const saveUdaDrListForm = (data = {} as any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const res = await api.put(`provider/profiles/udaDrList`, data)
        dispatch({type: ActionType.LOADER, payload: false})
        return res
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        return dispatch(errorHandler(err));
    }
}

export const getExcelProfiles = (data = {} as any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const query = Object.keys(data).map(key => `${key}=${data[key]}`).join('&');
        const res = await api.get(`provider/profiles/excel?${query}`);
        dispatch({type: ActionType.LOADER, payload: false});
        return res
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        return dispatch(errorHandler(err));
    }
};

export const getDoctorWorkHistory = (id: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const res = await api.get(`provider/workHistory/${id}`);
        dispatch({type: ActionType.LOADER, payload: false});
        return res
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        return dispatch(errorHandler(err));
    }
};

export const getProviderLockDetails = (userId: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const res = await api.get(`provider/lockedDetails/${userId}`);
        dispatch({type: ActionType.LOADER, payload: false});
        return res
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        return dispatch(errorHandler(err));
    }
};

export const getProfilesLockDetails = () => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const res = await api.get(`provider/profiles/lockhistory`);
        dispatch({type: ActionType.LOADER, payload: false});
        return res
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        return dispatch(errorHandler(err));
    }
};

export const saveLockDetails = (data: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const res = await api.post(`provider/lockhistory`, data);
        dispatch({type: ActionType.LOADER, payload: false});
        return res
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        return dispatch(errorHandler(err));
    }
};

export const getTotal = (data = {} as any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const query = Object.keys(data).map(key => `${key}=${data[key]}`).join('&');
        const response = await api.get(`provider/profiles/count?${query}`);
        dispatch({type: ActionType.LOADER, payload: false});
        return response;
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        dispatch(errorHandler(err))
        return [];
    }
};

export const getDoctorDayRate = (id: number) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const response = await api.get(`provider/orthoRate/${id}`);
        dispatch({type: ActionType.LOADER, payload: false});
        return response;
    } catch (error) {
        dispatch({type: ActionType.LOADER, payload: false});
        dispatch(errorHandler(error))
    }
}

export const updateOrthoDayRates = (orthoRateObj: object, userId: number) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    let payloadObj: any = {};
    payloadObj.orthoRateObj = Object.assign({}, orthoRateObj);
    payloadObj.userId = userId;
    try {
        const response = await api.put(`provider/updateOrthoRate`, payloadObj)
        dispatch({type: ActionType.LOADER, payload: false});
        return response;
    } catch (error) {
        dispatch({type: ActionType.LOADER, payload: false});
        dispatch(errorHandler(error))
    }
}

export const updateProviderDayRates = (providerRateObj: object, userId: number, docTabId: number) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    let payloadObj: any = {};
    payloadObj.providerRateObj = Object.assign({}, providerRateObj);
    payloadObj.userId = userId;
    payloadObj.docTabId = docTabId;                                                                                                                             
    try {
        const response = await api.put(`provider/updateProviderDayRate`, payloadObj);
        dispatch({type: ActionType.LOADER, payload: false});
        return response;
    } catch (error) {
        dispatch({type: ActionType.LOADER, payload: false});
        dispatch(errorHandler(error))
    }
}
export const getProviderDayRate = (id: number) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const response = await api.get(`provider/providerRate/${id}`);
        dispatch({type: ActionType.LOADER, payload: false});
        return response;
    } catch (error) {
        dispatch({type: ActionType.LOADER, payload: false});
        dispatch(errorHandler(error))
    }
}
export const updateRampThreshold = (rampThresholdObj: any, userId: number) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const response = await api.put(`provider/updateRampThreshold`, {
            userId,
            rampThresholdObj: Object.assign({}, rampThresholdObj)
        });
        dispatch({type: ActionType.LOADER, payload: false});
        return response;
    } catch (error) {
        dispatch({type: ActionType.LOADER, payload: false});
        dispatch(errorHandler(error))
    }
}
export const getDoctorRampThreshold = (userId: number) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const response = await api.get(`provider/rampThreshold/${userId}`);
        dispatch({type: ActionType.LOADER, payload: false});
        return response;
    } catch (error) {
        dispatch({type: ActionType.LOADER, payload: false});
        dispatch(errorHandler(error))
    }
}

export const getDoctorBaseWorkDays = (userId: number) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const response = await api.get(`provider/baseWorkDays/${userId}`);
        dispatch({type: ActionType.LOADER, payload: false});
        return response;
    } catch (error) {
        dispatch({type: ActionType.LOADER, payload: false});
        dispatch(errorHandler(error))
    }
}

export const updateBaseWorkDays = (baseWorkDaysObj: any,userId: number) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const response = await api.put(`provider/updateBaseWorkDays`,{
            userId,
            baseWorkDaysObj: Object.assign({}, baseWorkDaysObj)
        });
        dispatch({type: ActionType.LOADER, payload: false});
        return response;
    } catch (error) {
        dispatch({type: ActionType.LOADER, payload: false});
        dispatch(errorHandler(error))
    }
}

export const deleteProviderDayRate = (data: any) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const response = await api.post(`provider/deleteProviderDayRate`, data);
        dispatch({type: ActionType.LOADER, payload: false});
        return response;
    } catch (error) {
        dispatch({type: ActionType.LOADER, payload: false});
        dispatch(errorHandler(error))
    }
}
// get provider list options
export const getProviderListOptions = () => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const response = await api.get(`provider/doctorOptions`);
        // Format response into dropdown options
        const providerOptions = response.map((provider: any) => ({
            key: provider.id.toString(),
            value: provider.id,
            text: `${provider.firstName} ${provider.lastName}`
        }));
        
        dispatch({ type: ActionType.GET_PROVIDER_OPTIONS, payload: providerOptions });
        dispatch({ type: ActionType.LOADER, payload: false });
        return providerOptions;
    } catch (error) {
        dispatch({ type: ActionType.LOADER, payload: false });
        dispatch(errorHandler(error))
    }
}