export const daySinceLast = [
    {text: "Select Day", value: "all",},
    {text: "30", value: "30",},
    {text: "60", value: "60",},
    {text: "90", value: "90",},
    {text: "120", value: "120",},
    {text: "120+", value: "above",},
];

export const resultDropDown = [
    {
        text: "Select Result",
        value: "",
    },
    {
        text: "--Blank Result--",
        value: "Blank",
    },
    {
        text: "Appointment Made",
        value: "Appointment Made",
    },
    {
        text: "Text-NextHealth",
        value: "Text-NextHealth",
    },
    {
        text: "Left message",
        value: "Left message",
    },
    {
        text: "Contact Made Call Back",
        value: "Contact Made Call Back",
    },
    {
        text: "Contact Made Not Interested ",
        value: "Contact Made Not Interested",
    },
    {
        text: "CM-DNC- Other Dentist",
        value: "CM-DNC- Other Dentist",
    },
    {
        text: "CM-DNC- Moved",
        value: "CM-DNC- Moved",
    },
    {
        text: "Broken",
        value: "Broken",
    },
    {
        text: "Broken-Tx",
        value: "Broken-Tx",
    },
    {
        text: "No answer",
        value: "No answer",
    },
    {
        text: "Phone number issues",
        value: "Phone number issues",
    },
    {
        text: "Excessive Broken",
        value: "Excessive Broken",
    },
    {
        text: "DNC- Other Dentist",
        value: "DNC- Other Dentist",
    },
    {
        text: "DNC- Moved",
        value: "DNC- Moved",
    },
    {
        text: "DNC-Notes",
        value: "DNC-Notes",
    },
    {
        text: "Transfer",
        value: "Transfer",
    },
    {
        text: "Reschedule",
        value: "Reschedule",
    },
    {
        text: "Confirm",
        value: "Confirm",
    }
];

export const resultDropDownFilter = [
    {text: "--Blank Result--", value: "blank"},
    {text: "Appointment Made", value: "Appointment Made"},
    {text: "Text-NextHealth", value: "Text-NextHealth"},
    {text: "Left Message", value: "Left message"},
    {text: "Contact made call back", value: "Contact made call back"},
    {text: "Contact made not interested ", value: "Contact made not interested",},
    {text: "CM-DNC- Other Dentist", value: "CM-DNC- Other Dentist"},
    {text: "CM-DNC- Moved", value: "CM-DNC- Moved"},
    {text: "Broken", value: "Broken"},
    {text: "Broken-Tx", value: "Broken-Tx"},
    {text: "No answer", value: "No answer"},
    {text: "Phone number issues", value: "Phone number issues"},
    {text: "Excessive Broken", value: "Excessive Broken"},
    {text: "DNC- Other Dentist", value: "DNC- Other Dentist"},
    {text: "DNC- Moved", value: "DNC- Moved"},
    {text: "DNC-Notes", value: "DNC-Notes"},
    {text: "Transfer", value: "Transfer"},
    {text: "Reschedule", value: "Reschedule"},
    {text: "Confirm", value: "Confirm"},
];

export const patientStages = [
    {
        text: "Select Patient Stage",
        value: "",
    },
    {
        text: "Patient Stage",
        value: "Treatment",
    },
    {
        text: "New (Last visit is blank)",
        value: "New",
    },
    {
        text: "FDH (age<3 & Last Visit > 60 days)",
        value: "FDH",
    },
    {
        text: "Recare (Last Visit > 180 days)",
        value: "Recare",
    },
    {
        text: "No Next Apt (Last Visit < 180 days & No Next Appointments)",
        value: "NoNextApt",
    },
    {
        text: "No Next Apt (Last Visit < 2 years & No Next Appointments)",
        value: "NoNextAptTwoYrs",
    },
];

export const tableColumns = [
    {
        key: 'Guarantor',
        text: "GuarPN",
        value: "1"
    },
    {
        key: 'PatNum',
        text: "PatNum",
        value: "2",
    },
    {
        key: "Name",
        text: "Name",
        value: "3",
        order: 'PT_Name'
    },
    {
        key: "Email",
        text: "Email",
        value: "4",
        order: 'Email',
        hidden: true
    },
    {
        key: 'Practice',
        text: "Practice",
        value: "5",
        hidden: true
    },
    {
        key: "Address",
        text: "Address",
        value: "6",
        hidden: true
    },
    {
        text: "Phone Number",
        value: "7",
    },
    {
        text: "Birth Date",
        value: "8",
        hidden: true
    },
    {
        text: "Age",
        value: "9",
    },
    {
        text: "Show Rate",
        value: "10",
    },
    {
        text: "Day Since Last Apt",
        value: "11",
        hidden: true
    },
    {
        text: "Day Since Last Visit",
        value: "12",
        hidden: true
    },
    {
        text: "Note",
        value: "13",
    },
    {
        text: "Note time stamp",
        value: "14",
        hidden: true
    },
    {
        text: "Comm Log",
        value: "15",
    },
    {
        text: "Comm Log Time Stamp",
        value: "16",
        hidden: true
    },
    {
        text: "Comm Log £",
        value: "17",
        hidden: true
    },
    {
        text: "Result",
        value: "18",
    },
    {
        text: "Last Appointment",
        value: "19",
    },
    {
        text: "Last Visit",
        value: "20",
    },
    {
        text: "Next Appointment",
        value: "21",
    },
    {
        text: "Results Timestamp",
        value: "22",
        hidden: true
    },
    {
        text: "Ins Verification Date",
        value: "23",
    },
    {
        text: "Carrier",
        value: "24",
    },
    {
        text: "ML score",
        value: "25",
        hidden: true
    }
    ,
    {
        key: 'SubscriberID',
        text: "SubscriberID",
        value: "26",
        hidden: true
    }
];

export const resultContactTimeDropDown = [
    {
        text: "Result Contact Time",
        value: "",
    },
    {
        text: "WE-Mornings",
        value: "WE-Mornings",
    },
    {
        text: "WD-Mornings",
        value: "WD-Mornings",
    },
    {
        text: "WE-Afternoon",
        value: "WE-Afternoon",
    },
    {
        text: "WD-Afternoon",
        value: "WD-Afternoon",
    },
    {
        text: "WE-Evenings",
        value: "WE-Evenings",
    },
    {
        text: "WD-Evenings",
        value: "WD-Evenings",
    },
];

export const insuranceDropDown = [
    {
        text: "Insurance",
        value: "",
    },
    {
        text: "Active Insurance",
        value: "ActiveInsurance",
    },
    {
        text: "Insurance not Verified",
        value: "InsuranceNotVerified",
    },
    {
        text: "No Insurance",
        value: "NoInsurance",
    },
    {
        text: "Not Medicaid",
        value: "NotMedicaid",
    },
];

export const MCODropDown=[
    {
        text: "MCO",
        value: "",
    },
    {
        text: "DentaQuest",
        value: "DentaQuest",
    },
    {
        text: "MCNA",
        value: "MCNA",
    },
    {
            text: "United Healthcare",
            value: "United Healthcare",
    },
    {
        text: "TMHP",
        value: "TMHP",
    }
]

export const priceFilters = [
    {
        text: "Price",
        value: "",
    },
    {
        text: "<$100",
        value: "100",
    },
    {
        text: "<$200",
        value: "200",
    },
    {
        text: "<$300",
        value: "300",
    },
    {
        text: "<$400",
        value: "400",
    },
    {
        text: "<$500",
        value: "500",
    },
    {
        text: ">$500",
        value: "501",
    },
];
export const trimString = (str:any, len:number, addDots:boolean) => {
    return (
        (str && str.length > len) ?
            (addDots !== false ?
                str.substring(0, len - 3) :
                str.substring(0, len - 3) + '...') :
            str);
}

export const leadStatus = [
    {text: "ALL", value: ""},
    {text: "In Progress", value: "In Progress"},
    {text: "Lost", value: "Lost"},
    {text: "New", value: "New"},
    {text: "Won", value: "Won"},
];

export const aptoodAppointments = [
    {
        text: "All",
        value: "0",
    },
    {
        text: "Has Appointments",
        value: "1",
    },
    {
        text: "No Appointments",
        value: "2",
    },
]

export const mlScoreDropDown = [
    {
        text: "All",
        value: "",
    },
    {
        text: "90-100",
        value: "100",
    },
    {
        text: "80-89",
        value: "89",
    },
    {
        text: "50-79",
        value: "79",
    },
    {
        text: "30-49",
        value: "49",
    },
    {
        text: "1-29",
        value: "29",
    },
    {
        text: "0",
        value: "0",
    },
];