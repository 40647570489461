import React, { useEffect, useState } from 'react';
import { AccordionTitle, AccordionContent, Icon, Grid, GridRow, GridColumn, Loader, Button } from 'semantic-ui-react'
import ProgressBar from '../ProgressBar';

function HalopsaHealth() {
    const initialState = { panel: '', loading: false, data: [], response: [], counts: { failed: 0, success: 0, pending: 0 } };
    const [state, setState] = useState(initialState);

    const prefix = 'halopsa';
    const { panel, loading, counts } = state;

    const onSelect = (selected: string) => {
        if (panel === selected)
            setState({ ...state, panel: '' });
        else
            setState({ ...state, panel: selected });
    }


    useEffect(() => {
        setState({ ...state, counts: { failed: 0, pending: 0, success: 0 } });
    }, [])


    const openInNewWindow = () => {
        window.open(
            'https://cdphelp.halopsa.com/portal/dashboard?id=1025&publishedid=9e4d4dc2-f20f-4313-bb19-d6d529d29c9d',
            '_blank',
            'width=1200,height=600,noopener,noreferrer'
        );
    };

    return (
        <>
            <AccordionTitle active={panel === prefix} onClick={() => onSelect(prefix)}>
                <Grid>
                    <GridRow>
                        <GridColumn width={13} >
                            <Icon name='dropdown' />
                            <span style={{ fontSize: '15px', marginRight: '30px' }}>Halopsa</span>
                            {state.loading && <Loader active size='tiny' inline='centered' />}
                        </GridColumn>
                        <GridColumn width={3}>
                            <ProgressBar failed={counts.failed} pending={counts.pending} success={counts.success} />
                        </GridColumn>
                    </GridRow>
                </Grid>
            </AccordionTitle>
            <AccordionContent active={panel === prefix}>
                {!loading && <Grid>
                    <GridRow>
                        <GridColumn width={16}>
                            <Button className='mb5 left' primary content='Open Halopsa Dev Tickets' onClick={() => openInNewWindow()} />
                        </GridColumn>
                    </GridRow>
                </Grid>}

            </AccordionContent>
        </>
    );
}

export default HalopsaHealth;