import { ActionType } from "../../model/actionTypes";
import { TEDEntry } from "../../model/tedCollections";
import { errorHandler } from "./auth";

export const getTEDEntries = (id: number) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        // get all ted collections or one by id 

        const resp = await api.get(`ted-collections/${id ? id : 'all'}`);
        dispatch({ type: ActionType.GET_TED_ENTRIES, payload: resp });
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};

export const saveTEDEntry = (data: TEDEntry) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp = await api.post("ted-collections/create", data);
        dispatch({ type: ActionType.SAVE_TED_ENTRY, payload: resp });
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};

export const updateTEDEntry = (data: TEDEntry) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp = await api.post(`ted-collections/update`, data);
        dispatch({ type: ActionType.UPDATE_TED_ENTRY, payload: resp });
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};

export const deleteTEDEntry = (id: number) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp = await api.post(`ted-collections/delete`, { id });
        dispatch({ type: ActionType.DELETE_TED_ENTRY, payload: id });
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
}; 