import { User } from "../../../model/user";
import { connect } from "react-redux";
import Template from "../../../components/Template";
import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { HostedSite, PracticeData } from "./interfaces";
import { useActions } from "../../../redux/actions";
import * as awsHostedSitesActions from "../../../redux/actions/awsHostedSites";
import { Bar } from "react-chartjs-2";
import PerformanceChart from "./components/PerfomanceChart";
import EditSiteModal from './modals/EditSiteModal';
import * as sitesActions from "../../../redux/actions/awsHostedSites";
import * as practiceActions from "../../../redux/actions/practice";
import { SitesDetails } from "../../../model/awsHostedSites";


interface Props {
    auth: User
}

const initialState: {
    site: HostedSite | null,
    actualData: [],
    lighthouseResults: [],
    totalSites: 0
} = {
    site: null,
    actualData: [],
    lighthouseResults: [],
    totalSites: 0
}

function ViewSingleSite(props: Props) {
    
    const sitesAction = useActions(sitesActions)

    const awsHostedSitesAction = useActions(awsHostedSitesActions)

    const params: { siteId?: string } = useParams();
    const [state, setState] = useState(initialState);
    const [practices, setPractices] = useState([])

    const [showEditPopupModal, setShowEditPopupModal] = useState(false);
    const [selectedSiteData, setSelectedSiteData] = useState({})

    const setPracticesList = (res: any) => {
        let practiceList: any = [{id: 0, practice: 'CORP', brand: 'CDP'}];
        if (Object.keys(res).length) {
            res.forEach((item: any) => {
                practiceList.push({
                    id: item.id,
                    practice: item.practice,
                    brand: item.brand
                })
            })
            setPractices(practiceList)
        }
    }


    useEffect(() => {
        awsHostedSitesAction.getHostedSites().then((hostedSites: any) => {
            const removedDuplicates = hostedSites.reduce((accumulator: any, current: any) => {
                if (!accumulator.find((item: any) => item.practice === current.practice)){
                    accumulator.push(current)
                }
                return accumulator;
            }, [])
            awsHostedSitesAction.getLighthouseScores().then((lighthouseResults: any) => {
                setState({...state,
                    actualData: removedDuplicates,
                    lighthouseResults: lighthouseResults,
                    totalSites: removedDuplicates.length,
                })
            })
        })
        practiceActions.fetchWebsitesPractices(setPracticesList, '')
    }, []);


    const parsedId = parseInt("" + params.siteId);
    const siteId = Number.isNaN(parsedId) ? undefined : parsedId;

    function escapeSequencesToHTML(value: string): string {
        const escapeMap: {[key: string]: string} = {
          "\n": "<br>",
          " ": "&nbsp;", // Replace single spaces with non-breaking spaces
          "\t": "&nbsp;&nbsp;&nbsp;&nbsp;", 
          "&": "&amp;",
          '"': "&quot;",
          ">": "&gt;",
          "<": "&lt;"
        };
      
        return value.replace(/[\n\t&"'><]/g, (char) => escapeMap[char] || char);
      }
      

      const showEditSiteModal = (payload: any) => {
        setSelectedSiteData(payload)
        setShowEditPopupModal(!showEditPopupModal);
    }

    useEffect(() => {
        awsHostedSitesAction.getSingleWebsiteDetails(siteId).then((result: any) => {
            setState({
                ...state,
                site: result
            })
        });
    }, []);

    const onSubmit = (values: SitesDetails) => {
        setShowEditPopupModal(false)
        sitesAction.saveSite(values).then((res: any) => {
            awsHostedSitesAction.getHostedSites().then((results: any) => {
                const removedDuplicates = results.reduce((accumulator: any, current: any) => {
                    if (!accumulator.find((item: any) => item.practice === current.practice)){
                        accumulator.push(current)
                    }
                    return accumulator;
                }, [])
                setState({...state,
                    actualData: removedDuplicates,
                    lighthouseResults: removedDuplicates,
                    totalSites: removedDuplicates.length,
                })
            })
        })
    }

    const normalizeUrl = (inputUrl: string) => {
        // Regular expression to match common protocols at the start of a URL
        const protocolRegex = /^(https?:\/\/)/i;

        // Check if the input URL already has a protocol
        if (protocolRegex.test(inputUrl)) {
            return inputUrl; // Return the URL as-is if it has a protocol
        } else {
            return 'http://' + inputUrl; // Prepend 'http://' if no protocol was found
        }
    }

    const getPracticesLabels = (practiceList: string) => {
        const practices = practiceList.split(',');

        return (<ul className="website-practices">
            {practices.map((practice) => <li>
                {practice.trim()}
            </li>)}
        </ul>)
    }

    return (<Template activeLink='aws websites'>
        {showEditPopupModal && <EditSiteModal data={selectedSiteData} onSubmitHandler={onSubmit} showEditSiteModalHandler={showEditSiteModal} />}
        {siteId && <main className='aws-websites-root aws-websites-single poppins-regular'>
            <div className="websites-data-tables-section">
                <h1 className="inline-block">
                    <a title="Open the live website" target='_blank' href={normalizeUrl(`${state.site?.liveSite}`)}>Viewing {state.site?.liveSite}</a>

                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                        stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round"
                            d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244" />
                    </svg>
                </h1>

                <a title={`This website is hosted ${state.site?.contentManager === 'CDP' ? 'by CDP' : 'externally'} `} style={{ transform: "translateY(15px)", cursor: 'none', background: '#000' }} className="highlighted-label float-right" href="javascript:void(0)">{`${state.site?.contentManager === 'CDP' ? 'CDP' : 'External'}`} Hosted Site</a>
                <a title="" onClick={() => showEditSiteModal(state.site)} style={{ transform: "translateY(15px)", cursor: 'pointer', marginRight: '10px' }} className="highlighted-label float-right" href="javascript:void(0)">Edit Site</a>

                {state.site?.type === 'Wordpress' && <div className="inline-block float-right mr-xs" title="This is a WordPress site">
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 48 48">
                        <path fill="#fff" d="M24 4.050000000000001A19.95 19.95 0 1 0 24 43.95A19.95 19.95 0 1 0 24 4.050000000000001Z"></path><path fill="#01579b" d="M8.001,24c0,6.336,3.68,11.806,9.018,14.4L9.385,17.488C8.498,19.479,8.001,21.676,8.001,24z M34.804,23.194c0-1.977-1.063-3.35-1.67-4.412c-0.813-1.329-1.576-2.437-1.576-3.752c0-1.465,1.471-2.84,3.041-2.84 c0.071,0,0.135,0.006,0.206,0.008C31.961,9.584,28.168,8,24.001,8c-5.389,0-10.153,2.666-13.052,6.749 c0.228,0.074,0.307,0.039,0.611,0.039c1.669,0,4.264-0.2,4.264-0.2c0.86-0.057,0.965,1.212,0.099,1.316c0,0-0.864,0.105-1.828,0.152 l5.931,17.778l3.5-10.501l-2.603-7.248c-0.861-0.046-1.679-0.152-1.679-0.152c-0.862-0.056-0.762-1.375,0.098-1.316 c0,0,2.648,0.2,4.217,0.2c1.675,0,4.264-0.2,4.264-0.2c0.861-0.057,0.965,1.212,0.104,1.316c0,0-0.87,0.105-1.832,0.152l5.891,17.61 l1.599-5.326C34.399,26.289,34.804,24.569,34.804,23.194z M24.281,25.396l-4.8,13.952c1.436,0.426,2.95,0.652,4.52,0.652 c1.861,0,3.649-0.324,5.316-0.907c-0.04-0.071-0.085-0.143-0.118-0.22L24.281,25.396z M38.043,16.318 c0.071,0.51,0.108,1.059,0.108,1.645c0,1.628-0.306,3.451-1.219,5.737l-4.885,14.135C36.805,35.063,40,29.902,40,24 C40,21.219,39.289,18.604,38.043,16.318z"></path><path fill="#01579b" d="M4,24c0,11.024,8.97,20,19.999,20C35.03,44,44,35.024,44,24S35.03,4,24,4S4,12.976,4,24z M5.995,24 c0-9.924,8.074-17.999,18.004-17.999S42.005,14.076,42.005,24S33.929,42.001,24,42.001C14.072,42.001,5.995,33.924,5.995,24z"></path>
                    </svg>
                </div>}


                {getPracticesLabels(`${state.site?.practice}`)}

                <div className="stat-grid">
                    <div className="stat-card">
                    <h4>Roadmap</h4>
                <p dangerouslySetInnerHTML={{__html: escapeSequencesToHTML(`${state.site?.roadMap}`)}}></p>

                    </div>
                </div>
            </div>


            <div className="website-stats-grid mt-small">
                <div className="websites-data-tables-section in-grid">
                    <h2>
                        Uptime over last week
                    </h2>

                    <Bar options={{
                        scales: {
                            yAxes: [{
                                ticks: {
                                    min: 0,
                                    max: 100
                                }
                            }]
                        },
                        tooltips: {
                            callbacks: {
                                label: function (tooltipItem: any, data: any) {
                                    // @ts-ignore
                                    let label = data.datasets[tooltipItem.datasetIndex].label || '';

                                    if (label) {
                                        label += ': ';
                                    }
                                    label += tooltipItem.yLabel + '%';
                                    return label;
                                }
                            }
                        }
                    }} data={{
                        labels: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
                        datasets: [{
                            label: 'Uptime',
                            data: [100, 100, 75.4, 97.4, 99, 100, 100],
                            borderWidth: 1,

                        }]
                    }} />

                </div>

                <div className="websites-data-tables-section in-grid">
                    <h2>
                        Performance Metrics
                    </h2>


                    <div className="stat-grid">
                        <div className="stat-card">
                            <h4>Date Analyzed</h4>

                            <h2>Apr 25, 2024</h2>
                        </div>

                        <div className="stat-card">
                            <h4>Performance Score</h4>

                            <PerformanceChart performanceValue={74} />
                        </div>


                        <div className="stat-card">
                            <h4>Load Time</h4>

                            <PerformanceChart performanceValue={90} unit="s" />
                        </div>

                        <div className="stat-card">
                            <h4>SEO Score</h4>

                            <PerformanceChart performanceValue={70} unit="." />
                        </div>
                    </div>


                    <div className="stat-grid mt-md pb-sm">

                        <div className="stat-card">
                            <h4>Accessibility Score</h4>

                            <PerformanceChart performanceValue={74} unit="." />
                        </div>
                    </div>

                </div>

            </div>
        </main>}

        {!siteId && <main className='aws-websites-root failure aws-websites-single'>
            <h1>A catastrophic error has occurred while trying to render this page. Please return to the previous
                page.</h1>

            <a className={'has-icon'} href="/aws/websites">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                    stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3" />
                </svg>

                Back to websites dashboard
            </a>
        </main>}
    </Template>)
}

function mapStateToProps(state: any) {
    return {
        auth: state.auth,
    };
}

export default connect(mapStateToProps)(ViewSingleSite)
