import moment from "moment";
import {csvHeaderOrthoPCRPopUp, isSafari} from "../../constants";
import {ActionType} from "../../model/actionTypes";
import {
	OrthoPCRPopUpCSVObject,
	PMDSchedulesList,
	PracticeList,
	RegionsList,
	RegionsManagersList,
	UdaPracticeInfo
} from "../../model/practice";
import {removeNullProperties} from "../../utils/common";
import {errorHandler} from "./auth";

export const fetchPractices = (callBackFunc:Function,brand:string) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		let uri="practicesInfo/getPracticeList"
		uri+=brand?"?brand="+brand:"";
		const response:PracticeList[] = await api.get(uri);
		let orthPracticeNames: any = {};
		let practiceMap: any = {};
		let orthoDublicatePractice : any = [];
		if (response) {
            response.forEach((item: any) => {
				if(item.orthoLocation)
                	orthPracticeNames[item.orthoLocation.toLowerCase()] = item;
            });
			response.forEach((item: any) => {
                practiceMap[item.practice.toLowerCase()] = item;
            })
			orthoDublicatePractice = response.filter(item => item.ortho ===1)

        }		
		dispatch({ type: ActionType.FETCH_PRACTICES_DB,payload: { practiceList: removeNullProperties(orthPracticeNames),practiceNamesMap:removeNullProperties(practiceMap) }});
		const obj = callBackFunc(response);
		dispatch({ type: ActionType.FETCH_PRACTICES, payload: obj});
		let orthoObj = []
		if(orthoDublicatePractice){
			 orthoObj  = refineOrthoPracticeArray(orthoDublicatePractice);
		}
		return Array.from([...obj,...orthoObj]).sort((a, b) => (a.text > b.text ? 1 : -1));
	} catch (err) {
	}
};

export const fetchWebsitesPracticesAlt = () => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });

	const brand = '';
	const getUniqueByProperty = (array: Array<any>, key: any) => {
		const seen = new Set();
		return array.filter(item => {
			const itemId = item[key];
			return seen.has(itemId) ? false : (seen.add(itemId), true);
		});
	}

	const sortByName = (array: Array<any>) => {
		return array.sort((a, b) => {

			const itemA = (a.name || a.practiceName) || "Name Not Specified";
			const itemB = (b.name || b.practiceName) || "Name Not Specified";

			const nameA = itemA.trim().toUpperCase();
			const nameB = itemB.trim().toUpperCase(); // Case-insensitive comparison

			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}
			// names must be equal
			return 0;
		});
	}

	try {
		let normalList:PracticeList[] = [];

		const setNormalList  = (items: PracticeList[]) => normalList = items;

		fetchPractices(setNormalList, '');

		let practicesUri="practicesInfo/getPracticeList"
		practicesUri+=brand?"?brand="+brand:"";

		let uri="practicesInfo/getPracticeListWebsites"
		uri+=brand?"?brand="+brand:"";

		const response:PracticeList[] = await api.get(uri);
		const practicesResponse: PracticeList[] = await api.get(practicesUri)

		const data = [...response, ...practicesResponse]

		dispatch({ type: ActionType.FETCH_WEBSITE_PRACTICE_LIST_ALT, payload: sortByName(getUniqueByProperty(data, 'id')) });
		dispatch({ type: ActionType.LOADER, payload: false });
		return sortByName(getUniqueByProperty(data, 'id'));
	}catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
}

export const fetchWebsitesPractices = (callBackFunc:Function,brand:string) => async (dispatch: Function, getState: Function, api: any) => {
	const getUniqueByProperty = (array: Array<any>, key: any) => {
		const seen = new Set();
		return array.filter(item => {
			const itemId = item[key];
			return seen.has(itemId) ? false : (seen.add(itemId), true);
		});
	}

	const sortByName = (array: Array<any>) => {
		return array.sort((a, b) => {
			const itemA = (a.name || a.practiceName) || "Name Not Specified";
			const itemB = (b.name || b.practiceName) || "Name Not Specified";

			const nameA = itemA.trim().toUpperCase();
			const nameB = itemB.trim().toUpperCase(); // Case-insensitive comparison

			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}
			// names must be equal
			return 0;
		});
	}

	try {
		// noinspection DuplicatedCode
		let normalList:PracticeList[] = [];

		const setNormalList  = (items: PracticeList[]) => normalList = items;

		fetchPractices(setNormalList, '');

		let practicesUri="practicesInfo/getPracticeList"
		practicesUri+=brand?"?brand="+brand:"";

		let uri="practicesInfo/getPracticeListWebsites"
		uri+=brand?"?brand="+brand:"";

		const response:PracticeList[] = await api.get(uri);
		const practicesResponse: PracticeList[] = await api.get(practicesUri)

		const data = [...response, ...practicesResponse]

		const unique = sortByName( getUniqueByProperty(data, 'id') );

		callBackFunc(unique);

		return unique;
	} catch (err) {
	}
};


export const fetchPracticesOptions  = (_callBackFunc:Function, _brand:string)=> async (dispatch: Function, _getState: Function, _api: any) => {
	dispatch(fetchPractices(refinePracticeArray, ""));
};

export const fetchPracticesAll = (callBackFunc:Function,brand:string) => async (dispatch: Function, _getState: Function, api: any) => {

	try {
		let uri="practicesInfo/getPracticeListAll"
		uri+=brand?"?brand="+brand:"";
		const response:PracticeList[] = await api.get(uri);
		let orthPracticeNames: any = {};
		let practiceMap: any = {};
		if (response) {
			response.forEach((item: any) => {
				if(item.orthoLocation) {
					practiceMap[item.orthoLocation.toLowerCase()] = item;
				}else{
					practiceMap[item.practice.toLowerCase()] = item;
				}
			});
		}
		dispatch({ type: ActionType.FETCH_PRACTICES_DB,payload: { practiceList: removeNullProperties(practiceMap),practiceNamesMap:removeNullProperties(practiceMap) }});
		const obj = callBackFunc(response);
		dispatch({ type: ActionType.FETCH_PRACTICES, payload: obj});
		return obj;
	} catch (err) {
		return dispatch({ type: ActionType.FETCH_PRACTICES, payload: { practiceOptions: [] } });
	}
};

export const fetchPractice = (params: string) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		const response:PracticeList = await api.get(`practicesInfo/getPractice/${params}`);
		dispatch({ type: ActionType.FETCH_PRACTICE, payload: response});
		dispatch({ type: ActionType.FETCH_PRACTICES_DB, payload: response});
		dispatch({ type: ActionType.INCREMENT, payload: 1});
		return response;
	} catch (err) {
		dispatch({ type: ActionType.INCREMENT, payload: 1});
		return dispatch({ type: ActionType.FETCH_PRACTICE, payload: { practice: {} } });		
	}
};

export const getPractices = (params = {} as any) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		dispatch({type: ActionType.LOADER, payload: true});
		const query = Object.keys(params).map(key => `${key}=${params[key]}`).join('&');
		const response = api.get(`practicesInfo/getPractice?${query}`);
		dispatch({type: ActionType.LOADER, payload: false});
		return response;
	} catch (err) {
		dispatch({type: ActionType.LOADER, payload: false});
		return [];
	}
};

export const getPracticesList = (params = {} as any) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		return api.get(`practicesInfo/practice`);
	} catch (err) {
		return [];
	}
};

export const fetchPracticeUda = () => async (dispatch: Function, getState: Function, api: any) => {
	try {
		const response:UdaPracticeInfo[] = await api.get(`practicesInfo/getUdaPractices`);
		dispatch({ type: ActionType.FETCH_UDA_INFO_PRACTICES, payload: {
			udaPracticeInfo:response
		}});
		return response;
	} catch (err) {
		dispatch({ type: ActionType.INCREMENT, payload: 1});	
	}
};

export const fetchWebSitesPracticeList = () => async (dispatch: Function, getState: Function, api: any) => {
	try {
		const response:UdaPracticeInfo[] = await api.get(`practicesInfo/getWebsitesPracticeList`);
		dispatch({ type: ActionType.FETCH_WEBSITES_PRACTICE_LIST, payload: response});
		return response;
	} catch (err) {
		dispatch({ type: ActionType.INCREMENT, payload: 1});
	}
};

export const fetchOrthoPractices=()=>async (dispatch:Function,getState:Function,api:any)=>{
	try{
		const response=await api.get(`practicesInfo/getOrthoPractices`)
		dispatch({
			type:ActionType.FETCH_ORTHO_PRACTICES,payload:{
				orthoPractices:response
			}
		})
	}catch(err){
		dispatch({type:ActionType.INCREMENT,payload:1})
	}
}

export const fetchRegions = (callBackFunc: Function) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		const response:any = await api.get("practicesInfo/regions");
		const regionOptions:[] = callBackFunc ? callBackFunc(response.regions) : [];
		dispatch({
			type: ActionType.FETCH_REGIONS,
			payload: {
				regionPracticeList : response.regionPracticeList,
				regions : response.regions,
				regionOptions: regionOptions,
				regionPractices: response.regionPractices,
				drPractices: response.drPractices ,
				regionPracticeIds:response.regionPracticeIds
			} });
		return response.regions;
	} catch (err) {
		return dispatch({ type: ActionType.FETCH_REGIONS, payload: { regionOptions: [] } });
	}
};

export const fetchRegionManagers = (callBackFunc: Function,defaultPractice:string) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		const response:RegionsManagersList = await api.get("practicesInfo/regionmanagers");
		const regionManagersOptions:[] = callBackFunc(response,defaultPractice);
		dispatch({ type: ActionType.FETCH_REGIONMANAGERS, payload: { regionManagersOptions: regionManagersOptions} });
		dispatch({ type: ActionType.INCREMENT, payload: 1});
	} catch (err) {
		dispatch({ type: ActionType.INCREMENT, payload: 1});
		return dispatch({ type: ActionType.FETCH_REGIONMANAGERS, payload: { regionManagersOptions: [] } });		
	}
};

export const fetchScheduleDetails = (params: string) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		const response:PMDSchedulesList = await api.get(`practicesInfo/schedules/${params}`);
		dispatch({ type: ActionType.INCREMENT, payload: 1});
		return response;
	} catch (err) {
		dispatch({ type: ActionType.INCREMENT, payload: 1});
		return dispatch({ type: ActionType.FETCH_PMD_SCHEDULES, payload: { schedulePMDDataOptions: [] } });		
	}
};

export const sendMailOrthoPCRPopUp = (params:any, dataPoint: string, info: any) => async (dispatch: Function, getState: Function, api: any) => {
    try {       
        let orthoPCRCSVobj:OrthoPCRPopUpCSVObject[] = [];
        orthoPCRCSVobj.push(csvHeaderOrthoPCRPopUp);

		let csvContent = info.toString();
		csvContent += "\r\n";

		if (dataPoint !== "Work Days") {
			params.data.forEach(function(rowArray: any) {
				const {PatientID, ProcDate, Location, Procfee, Next_Apt, Abbr, PatientTotalDue, AdjAmt, ProcCode, ContractTotal, typeOf, PatientStatusDescription} = rowArray;
				let row = PatientID + "," + ProcDate + "," + Location + "," + Procfee + "," + Next_Apt ? moment(Next_Apt).format('DD-MMM-YYYY') : '' + "," + Abbr + "," + PatientTotalDue + "," + AdjAmt + "," + ProcCode + "," + ContractTotal + "," + typeOf + "," + PatientStatusDescription;
				csvContent += row + "\r\n";
			});
		} else {
			params.data.forEach(function(rowArray: any) {
			  const {id, desc, isPublished, location, startDate, end, title, type} = rowArray;
			  const descp = desc.replace(';', '');
			  let row = id + "," + descp + "," + isPublished + "," + location + "," + moment(startDate).format('DD-MMM-YYYY') + "," + moment(end).format('DD-MMM-YYYY') + "," + title + "," + type;
				csvContent += row + "\r\n";
			});
		}

        if (orthoPCRCSVobj.length) {
            params.data = csvContent;
            await api.post(`report/sendMailOrthoPCRPopUp`,params);
            dispatch({ type: ActionType.LOADER, payload: false });
            return true;
        }
	} catch (err) {
        console.log(err);
        return dispatch({ type: ActionType.LOADER, payload: false });
	}
}

export const sendMailCSVOrthoPCR = (params:any) => async (dispatch: Function, getState: Function, api: any) => {
    try {       
		if(params.download){
			exportCSVOrthoPCR("Ortho PCR "+moment().format('DD-MMM-YYYY')+'.csv', params.data);

		}else{
			await api.post(`report/sendMailCSVOrthoPCR`,params);
		dispatch({ type: ActionType.LOADER, payload: false });
		}				
	} catch (err) {
        console.log(err);
        return dispatch({ type: ActionType.LOADER, payload: false });
	}
}

export const sendMailPDFOrthoPCR = (params:any) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		await api.post(`report/sendMailPDFOrthoPCR`,params);
		dispatch({ type: ActionType.LOADER, payload: false });
		return true;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch({ type: ActionType.LOADER, payload: false });
	}
};

export const exportCSVOrthoPCR: any = (fileName:string, csvData:any) => {
    const dataType = isSafari() ? 'application/csv' : 'text/csv';
    const blob = new Blob(['', csvData], {type: dataType});
    const url = (window.URL || window.webkitURL).createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
};

const refinePracticeArray = (response: any) => {
	let practiceNames: Array<string> = [];
	let practiceInfo: any = {};
	let practiceOptions = [{key: 'none', value: 'all', text: 'All Practices'}];
	let practiceOptionsList: any[] = [];
	if (response) {
		response.forEach((item: any) => {
			practiceOptions.push({text: item.practice, value: item.practice, key: item.id.toString()});
			practiceNames.push(item.practice);
			practiceInfo[item.practice.toLowerCase()] = item;
			practiceOptionsList.push({text: item.practice, value: item.id, key: item.id.toString()});
		})
	}
	return {practiceNames: practiceNames, practiceOptions: practiceOptions, practiceInfo: practiceInfo, practiceOptionsList: practiceOptionsList}
};

const refineOrthoPracticeArray = (response: any) => {
	let practiceOptions:any = [];
	if (response) {
		response.forEach((item: any) => {
			practiceOptions.push({text:`${item.practice} Ortho` , value: `${item.practice} Ortho`, key: item.id.toString()+"1"});
		})
	}
	return practiceOptions;
};

export const fetchOpeningHours = () => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const openingHours = await api.get(`practicesInfo/getOpeningHours`);
		dispatch({ type: ActionType.LOADER, payload: false });
		return openingHours;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return {}
	}
}

export const fetchUdaPractices = () => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const openingHours = await api.get(`practicesInfo/udaPracticeList`);
		dispatch({ type: ActionType.LOADER, payload: false });
		return openingHours;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return {}
	}
}

export const getAllPractices = () => async (dispatch: Function, getState: Function, api: any) => {
	try {
		const response = await api.get(`practicesInfo/getAllPractices`);
		dispatch({type: ActionType.FETCH_ALL_PRACTICES, payload: {allPracticeList : response} });
		dispatch({type: ActionType.LOADER, payload: false});
		return response;
	} catch (error) {
		dispatch({type: ActionType.LOADER, payload: false});
		dispatch(errorHandler(error))
	}
}

export const getDentaHubPractices = () => async (dispatch: Function, getState: Function, api: any) => {
	try {
		const response = await api.get(`practicesInfo/dentaHubPractices`);
		dispatch({type: ActionType.LOADER, payload: false});
		return response;
	} catch (error) {
		dispatch({type: ActionType.LOADER, payload: false});
		dispatch(errorHandler(error))
	}
}