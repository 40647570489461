import { App } from "../../model";
import { Action, ActionType } from "../../model/actionTypes";
import createReducer from "./createReducer";
const initialState:App = {
	leftSidebarOpen: false,
	loader: false,
	practiceOptions:[],
	providerOptions:[],
	regionOptions:[],
	brandOptions:[]
}
export const app = createReducer(initialState, {
	[ActionType.SIDEBAR_OPEN](state: App, action: Action<App>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.LOADER](state: App, action: Action<App>) {
		return Object.assign({...state}, { loader: action.payload });
	},
	[ActionType.SAVE_USAGE](state: App, action: Action<App>) {
		return Object.assign({...state},  action.payload );
	},
	[ActionType.UPDATE_PRACTICES_OPTIONS](state:App,action:Action<App>){
		return Object.assign({...state},action.payload)
	},
	[ActionType.UPDATE_REGION_OPTIONS](state:App,action:Action<App>){
		return Object.assign({...state},action.payload)
	},
	[ActionType.UPDATE_BRANDS_OPTIONS](state:App,action:Action<App>){
		return Object.assign({...state},action.payload)
	},
	[ActionType.GET_PROVIDER_OPTIONS](state: App, action: Action<App>) {
		return Object.assign({...state}, { providerOptions: action.payload });
	}
	
});
