import React, { useEffect, useState } from "react";
import Template from "../Template";
import { RootState } from "../../model";
import { connect } from "react-redux";
import * as TEDActions from "../../redux/actions/tedCollections";
import * as practiceActions from "../../redux/actions/practice";
import * as providerActions from "../../redux/actions/provider";
import { useActions } from "../../redux/actions";
import { Button, Confirm, Icon, Search, Table, Dropdown, Modal, Form, Message } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import _ from "lodash";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { TEDEntry } from "../../model/tedCollections";
import TEDCollectionForm from './TEDCollectionForm';
// lets add dummy data to the tedEntries array so that we can see the table
const dummyData = [
    {
        providerId: 1,
        providerName: "Provider 1",
        practiceId: 1,
        practiceName: "Practice 1",
        startDate: new Date(),
        endDate: new Date()
    }
]

// Add interface for state
interface State {
    loading: boolean;
    tedEntries: TEDEntry[];  // Changed from never[] to TEDEntry[]
    isEdit: boolean;
    initialValues: {
        id: number | undefined;
        providerId: number;
        practiceId: number;
        startDate: Date | undefined;
        endDate: Date | undefined;
    };
    orderBy: string | null;
    order: boolean;
    search: string;
    showDeleteConfirmation: boolean;
    deletingItem: TEDEntry | null;
    practices: any[];
    providers: any[];
}

const initialState = {
    loading: false,
    tedEntries: [],
    isEdit: false,
    initialValues: { id: undefined, providerId: 0, practiceId: 0, startDate: undefined, endDate: undefined },
    orderBy: null,
    order: true,
    search: "",
    showDeleteConfirmation: false,
    deletingItem: null,
    practices: [],
    providers: []
};

interface TEDCollectionsProps {
  practices: any[];  // Replace 'any' with proper type if available
  providers: any[];
}

const TEDCollections = ({ practices = [], providers = [] }: TEDCollectionsProps) => {
    const tedActions = useActions(TEDActions);
    const practiceAction = useActions(practiceActions);
    const providerAction = useActions(providerActions);
    const [state, setState] = useState<State>(initialState);
    const [message, setMessage] = useState<{text: string, type: 'success' | 'error'} | null>(null);

    useEffect(() => {
        setState(prevState => ({...prevState, loading: true}));
        tedActions.getTEDEntries()
            .then((res: any) => {
                if (res && Array.isArray(res)) {
                    setState(prevState => ({...prevState, tedEntries: res, loading: false}));
                }
            })
            .catch(() => {
                setState(prevState => ({...prevState, loading: false}));
            });
    }, []);

    useEffect(() => {
        getInitialData();
    }, []);

    const getInitialData = () => {
        practiceAction.fetchPracticesOptions();
        providerAction.getProviderListOptions();
    }

    const getSortRows = (sortColumn: string, orderLocal: boolean) => {
        const orderUpdated = !orderLocal;
        const tedEntriesSort = _.orderBy(state.tedEntries, [sortColumn], [orderLocal ? 'asc' : 'desc']);
        setState({...state, tedEntries: tedEntriesSort, orderBy: sortColumn, order: orderUpdated});
    };

    const onSearch = (e: any, element: any) => {
        setState({...state, search: element.value});
    };

    const handleEdit = (entry: any) => {
        setState({
            ...state,
            isEdit: true,
            initialValues: {
                id: entry.id,
                providerId: entry.providerId,
                practiceId: entry.practiceId,
                startDate: new Date(entry.startDate),
                endDate: new Date(entry.endDate)
            }
        });
    };

    const handleSave = (values: any) => {
        const func = values.id ? "updateTEDEntry" : "saveTEDEntry";
        tedActions[func](values).then(() => {
            tedActions.getTEDEntries()
                .then((res: any) => {
                    setState({
                        ...state,
                        tedEntries: res,
                        isEdit: false,
                        initialValues: { id: undefined, providerId: 0, practiceId: 0, startDate: undefined, endDate: undefined }
                    });
                });
        });
    };

    const handleDelete = () => {
        if (state.deletingItem) {
            setState(prevState => ({ ...prevState, loading: true }));
            tedActions.deleteTEDEntry(state.deletingItem.id)
                .then(() => {
                    tedActions.getTEDEntries()
                        .then((res: any) => {
                            setState(prevState => ({
                                ...prevState,
                                tedEntries: res,
                                showDeleteConfirmation: false,
                                deletingItem: null,
                                loading: false
                            }));
                            setMessage({ text: 'TED Collection deleted successfully!', type: 'success' });
                            // Clear message after 3 seconds
                            setTimeout(() => setMessage(null), 3000);
                        });
                })
                .catch(() => {
                    setState(prevState => ({ ...prevState, loading: false }));
                });
        }
    };

    const { tedEntries, search, orderBy, order, isEdit } = state;
    const direction = order ? 'sort down' : 'sort up';

    return (
        <Template activeLink="ted-collections">
            {message && (
                <Message 
                    success={message.type === 'success'}
                    error={message.type === 'error'}
                    content={message.text}
                />
            )}
            <div className="ui card">
                <div className="content pb0">
                    <h2 className="float-left mr10">TED Collections</h2>
                    <div className="topFilters flex items-end flex-wrap">
                        <Button
                            primary={true}
                            onClick={() => setState({
                                ...state,
                                isEdit: true,
                                initialValues: { id: undefined, providerId: 0, practiceId: 0, startDate: undefined, endDate: undefined }
                            })}
                        >
                            Create New
                        </Button>
                        <Search
                            placeholder='Search...'
                            onSearchChange={onSearch}
                            name={'search'}
                        />
                    </div>
                </div>
            </div>

            {state.loading ? (
                <div className="text-center p-4">
                    <Icon loading name='spinner' size='large' />
                </div>
            ) : tedEntries.length === 0 ? (
                <div className="text-center p-4">
                    <p>No records found</p>
                </div>
            ) : (
                <Table className="ui table table-striped table-hover celled unstackable">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell
                                className={`cursorPointer ${orderBy === 'providerName' ? 'active' : ''}`}
                                onClick={() => getSortRows('providerName', order)}
                            >
                                Provider
                                <Icon className={orderBy === 'providerName' ? direction : 'sort'}/>
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                className={`cursorPointer ${orderBy === 'practiceName' ? 'active' : ''}`}
                                onClick={() => getSortRows('practiceName', order)}
                            >
                                Practice
                                <Icon className={orderBy === 'practiceName' ? direction : 'sort'}/>
                            </Table.HeaderCell>
                            <Table.HeaderCell>Start Date</Table.HeaderCell>
                            <Table.HeaderCell>End Date</Table.HeaderCell>
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {tedEntries.filter((item: any) => {
                            if (!search) return true;
                            const values = Object.values(item).join(" ");
                            return values.toLowerCase().includes(search.toLowerCase());
                        }).map((item: any, index: number) => (
                            <Table.Row key={index}>
                                <Table.Cell>{item.provider ? `${item.provider.firstName} ${item.provider.lastName}` : 'Unknown Provider'}</Table.Cell>
                                <Table.Cell>
                                    {practices?.find(practice => practice.value === item.practiceId)?.text || 'Unknown Practice'}
                                </Table.Cell>
                                <Table.Cell>{moment(item.startDate).format('MM/DD/YYYY')}</Table.Cell>
                                <Table.Cell>{moment(item.endDate).format('MM/DD/YYYY')}</Table.Cell>
                                <Table.Cell>
                                    <Icon
                                        className="cursorPointer"
                                        name="edit"
                                        onClick={() => handleEdit(item)}
                                    />
                                    <Icon
                                        className="cursorPointer ml10"
                                        name="trash alternate"
                                        onClick={() => setState({
                                            ...state,
                                            showDeleteConfirmation: true,
                                            deletingItem: item
                                        })}
                                    />
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            )}
            {isEdit && (
                <TEDCollectionForm
                    initialValues={state.initialValues}
                    cancelForm={() => setState({ ...state, isEdit: false })}
                    saveForm={handleSave}
                    practices={practices}
                    providers={providers}
                />
            )}
            <Confirm
                open={state.showDeleteConfirmation}
                onCancel={() => setState({ ...state, showDeleteConfirmation: false, deletingItem: null })}
                onConfirm={handleDelete}
                content="Are you sure you want to delete this TED Collection?"
            />
        </Template>
    );
};

function mapStateToProps(state: RootState) {
    return {
        tedCollections: state.tedCollections,
        practices: state.practice.practiceOptionsList,
        providers: state.app.providerOptions,
    };
}

export default connect(mapStateToProps)(TEDCollections); 