import { ActionType } from "../../model/actionTypes";
import { TEDCollectionsState } from "../../model/tedCollections";

const initialState: TEDCollectionsState = {
    loading: false,
    tedEntries: [],
    error: null
};

export default function tedCollectionsReducer(state = initialState, action: any) {
    switch (action.type) {
        case ActionType.GET_TED_ENTRIES:
            return {
                ...state,
                tedEntries: action.payload,
                error: null
            };
        case ActionType.SAVE_TED_ENTRY:
            return {
                ...state,
                tedEntries: [...state.tedEntries, action.payload],
                error: null
            };
        case ActionType.UPDATE_TED_ENTRY:
            return {
                ...state,
                tedEntries: state.tedEntries.map(entry => 
                    entry.id === action.payload.id ? action.payload : entry
                ),
                error: null
            };
        case ActionType.DELETE_TED_ENTRY:
            return {
                ...state,
                tedEntries: state.tedEntries.filter(entry => 
                    entry.id !== action.payload
                ),
                error: null
            };
        default:
            return state;
    }
} 