import moment from 'moment'
import React from 'react'
import {
    TableRow,
    TableHeaderCell,
    TableHeader,
    TableCell,
    TableBody,
    Table,
    Label,
} from 'semantic-ui-react'
const _ = require('underscore');

interface props {
    records: any
}
const OpenDentalStatusTable = (props: props) => {
    const { records } = props;
    return (
        <>
            <Table striped compact='very'>
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell>Practice</TableHeaderCell>
                        <TableHeaderCell>Service</TableHeaderCell>
                        <TableHeaderCell>Last updated</TableHeaderCell>
                        <TableHeaderCell>Status</TableHeaderCell>
                        <TableHeaderCell>Time Elapsed</TableHeaderCell>
                    </TableRow>
                </TableHeader>

                <TableBody>
                    {
                      records && records.map((logs: any, index: number) =>
                            <TableRow key={index}>
                                <TableCell>{logs?.apiConfig?.practice?.practice}</TableCell>
                                <TableCell>{logs.location}</TableCell>
                                <TableCell>{moment(logs?.updatedAt).format('llll')}</TableCell>
                                <TableCell width={3}>{logs.isReachable ?
                                    <Label style={{width:'100%'}} size='massive' color='green' horizontal></Label> :
                                    <Label style={{width:'100%'}} size='massive' color='red' horizontal></Label>}
                                </TableCell>
                                <TableCell >
                                    {logs.timeElapsed ? (
                                        <Label
                                        style={{ width: '50%' }}
                                        size='large'
                                        color={
                                            logs.timeElapsed > 500
                                            ? 'red'
                                            : logs.timeElapsed > 200
                                            ? 'yellow'
                                            : 'green'
                                        }
                                        horizontal
                                        >
                                        {logs.timeElapsed} ms
                                        </Label>
                                    ) : (
                                        // Handle the case where timeElapsed is undefined or null
                                        <Label style={{ width: '50%' }} size='large' color='grey' horizontal>
                                        N/A
                                        </Label>
                                    )}
                                    </TableCell>
                                </TableRow>
                        )
                    }
                </TableBody>
            </Table>
        </>

    )
}

export default OpenDentalStatusTable