import React, { useEffect, useState } from "react";
import { RootState } from "../../model";
import { connect } from "react-redux";
import { api } from '../../redux/configure'
import {
    Card,
    Dimmer,
    Loader,
    Table,
    Input,
    Button,
    Icon,
    Modal,
    Form,
    Message
} from "semantic-ui-react";
import Template from "../Template";

// Utility function to compare version strings numerically
const compareVersions = (a: string, b: string): number => {
    const aParts = a.split('.').map((num) => parseInt(num, 10));
    const bParts = b.split('.').map((num) => parseInt(num, 10));

    // Compare each part of the version
    for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
        const aPart = aParts[i] || 0;
        const bPart = bParts[i] || 0;

        if (aPart < bPart) return -1;
        if (aPart > bPart) return 1;
    }
    return 0;
};

type ApplicationVersionInformation = {
    repoName: string;
    nodeVersion: string | null;
    reactVersion: string | null;
    outdated: {
        node: boolean | null;
        react: boolean | null;
    };
};

type ReposResponse = {
    cache: boolean;
    cacheAge: number;
    data: Array<ApplicationVersionInformation>;
    NodeLTS: string;
    ReactLTS: string;
};

interface VersionTrackingProps {
    user: any;
}

const VersionTracking: React.FC<VersionTrackingProps> = (props) => {
    const toolingEndpoint = "https://tooling.cdp.dental";

    const [state, setState] = useState({
        loading: true,
        isUsingCachedData: false,
        cacheAge: 0,
        repositories: [] as ApplicationVersionInformation[],
        searchQuery: "",
        nodeLTS: "",
        reactLTS: "",
        oldestNodeVersion: "",
        oldestNodeRepo: "",
        oldestReactVersion: "",
        oldestReactRepo: "",
        newestNodeVersion: "",
        newestReactVersion: "",
        newestNodeRepo: "",
        newestReactRepo: "",
        sortBy: "repoName",
        sortOrder: "asc",
    });

    const [modalOpen, setModalOpen] = useState(false);
    const [emailOption, setEmailOption] = useState("user");
    const [customEmail, setCustomEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [notification, setNotification] = useState<{ type: string; message: string } | null>(null);

    // Validate email format
    const isValidEmail = (email: string): boolean => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    // Fetch data on mount
    useEffect(() => {
        const loadData = async () => {
            try {
                const result = await fetch(`${toolingEndpoint}/api/repos`);
                const data: ReposResponse = await result.json();

                let oldestNodeVersion = "9999.9999.9999";
                let oldestReactVersion = "9999.9999.9999";
                let newestNodeVersion = "0.0.0";
                let newestReactVersion = "0.0.0";
                let oldestNodeRepo = "";
                let oldestReactRepo = "";
                let newestNodeRepo = "";
                let newestReactRepo = "";

                data.data.forEach((repo) => {
                    if (repo.nodeVersion && compareVersions(repo.nodeVersion, oldestNodeVersion) < 0) {
                        oldestNodeVersion = repo.nodeVersion;
                        oldestNodeRepo = repo.repoName;
                    }

                    if (repo.reactVersion && compareVersions(repo.reactVersion, oldestReactVersion) < 0) {
                        oldestReactVersion = repo.reactVersion;
                        oldestReactRepo = repo.repoName;
                    }

                    if (repo.nodeVersion && compareVersions(repo.nodeVersion, newestNodeVersion) > 0) {
                        newestNodeVersion = repo.nodeVersion;
                        newestNodeRepo = repo.repoName;
                    }

                    if (repo.reactVersion && compareVersions(repo.reactVersion, newestReactVersion) > 0) {
                        newestReactVersion = repo.reactVersion;
                        newestReactRepo = repo.repoName;
                    }
                });

                setState((prevState) => ({
                    ...prevState,
                    repositories: data.data,
                    isUsingCachedData: data.cache,
                    cacheAge: data.cacheAge,
                    loading: false,
                    nodeLTS: data.NodeLTS,
                    reactLTS: data.ReactLTS,
                    oldestNodeVersion,
                    oldestNodeRepo,
                    oldestReactVersion,
                    oldestReactRepo,
                    newestNodeVersion,
                    newestReactVersion,
                    newestNodeRepo,
                    newestReactRepo,
                }));
            } catch (error) {
                console.error("Error fetching repository data", error);
                setState((prevState) => ({ ...prevState, loading: false }));
            }
        };

        loadData();
    }, []);

    const handleExportAndEmail = async () => {
        const email = emailOption === "user" ? props.user.email : customEmail;

        if (!isValidEmail(email)) {
            setEmailError("Please enter a valid email address");
            return;
        }

        const payload = {
            email,
            data: state.repositories,
        };

        try {
            const response = await api.post("versions/email", payload, "");

            if (response){
                setNotification({ type: "success", message: "PDF report sent successfully!" });
            } else {
                setNotification({ type: "error", message: "Failed to send the report. Please try again." });
            }
        } catch (error) {
            setNotification({ type: "error", message: "Network error. Please try again later." });
        } finally {
            setModalOpen(false);
            setEmailError("");
        }
    };

    const formatVersion = (version: string | null): string => {
        if (!version) {
            return "Version could not be read from repository";
        }

        let formattedVersion = version.replace(/^(\^|v)/, '').trim();

        if (!formattedVersion) {
            return "Version could not be read from repository";
        }

        return formattedVersion;
    };

    const getVersionStatus = (isOutdated: boolean | null, type: 'Node' | 'React') => {
        if (isOutdated === null) return "N/A";
        const statusText = isOutdated ? `${type} Outdated` : `${type} Up to Date`;
        return (
            <>
                {isOutdated ? (
                    <Icon name="warning circle" color="red" />
                ) : (
                    <Icon name="check circle" color="green" />
                )}
                <span>{statusText}</span>
            </>
        );
    };

    const handleSort = (column: string) => {
        const isSameColumn = state.sortBy === column;
        setState((prevState) => ({
            ...prevState,
            sortBy: column,
            sortOrder: isSameColumn && prevState.sortOrder === "asc" ? "desc" : "asc",
        }));
    };

    const formatCacheAge = (cacheAge: number) => {
        const hours = Math.floor(cacheAge / (60 * 60 * 1000));
        const minutes = Math.floor((cacheAge % (60 * 60 * 1000)) / (60 * 1000));
        return `${hours} hours ${minutes} minutes`;
    };

    const renderSortIcon = (column: string) => {
        if (state.sortBy === column) {
            return state.sortOrder === "asc" ? (
                <Icon name="sort up" />
            ) : (
                <Icon name="sort down" />
            );
        }
        return <Icon name="sort" />;
    };

    const filteredRepositories = state.repositories.filter((repo) =>
        repo.repoName.toLowerCase().includes(state.searchQuery.toLowerCase())
    );

    const sortedRepositories = [...filteredRepositories].sort((a, b) => {
        const sortColumn = state.sortBy;
        const sortOrder = state.sortOrder === "asc" ? 1 : -1;

        if (sortColumn === "nodeVersion" || sortColumn === "reactVersion") {
            return compareVersions(a[sortColumn] || "", b[sortColumn] || "") * sortOrder;
        }

        if (a[sortColumn] < b[sortColumn]) {
            return -1 * sortOrder;
        } else if (a[sortColumn] > b[sortColumn]) {
            return 1 * sortOrder;
        }
        return 0;
    });

    return (
        <Template activeLink="application-version-tracking">
            {notification && (
                <Message
                    style={{
                        position: "fixed",
                        top: "10px",
                        left: "10px",
                        zIndex: 9999,
                        maxWidth: "300px",
                        fontSize: "12px",
                        padding: "10px",
                    }}
                    color={notification.type === "error" ? "red" : "green"}
                    icon={notification.type === "error" ? "exclamation triangle" : "check circle"}
                    header={notification.type === "error" ? "Error" : "Success"}
                    content={notification.message}
                    onDismiss={() => setNotification(null)}
                />
            )}

            <Card>
                <Card.Content>
                    <h2>Application Versions</h2>
                    {state.isUsingCachedData && (
                        <p style={{ fontSize: "14px", color: "#999", background: "#f4f4f4", padding: "5px" }}>
                            (Using cached data, age: {formatCacheAge(state.cacheAge)})
                        </p>
                    )}
                    <Input
                        icon="search"
                        placeholder="Search by repository name"
                        value={state.searchQuery}
                        onChange={(e) => setState({ ...state, searchQuery: e.target.value })}
                        style={{ marginBottom: "15px", width: "100%", padding: "10px" }}
                    />
                    <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px", background: "#f9f9f9", padding: "10px", borderRadius: "5px" }}>
                        <div style={{ flex: 1, marginRight: "10px" }}>
                            <p><strong>Current LTS Versions:</strong></p>
                            <p>Node.js LTS: {state.nodeLTS}</p>
                            <p>React LTS: {state.reactLTS}</p>
                        </div>
                        <div style={{ flex: 1 }}>
                            <p><strong>Oldest Versions:</strong></p>
                            <p>Oldest Node Version: {state.oldestNodeVersion} (Repo: {state.oldestNodeRepo})</p>
                            <p>Oldest React Version: {state.oldestReactVersion} (Repo: {state.oldestReactRepo})</p>
                        </div>
                    </div>
                </Card.Content>
            </Card>

            <div style={{ marginBottom: "20px" }}>
                <Card>
                    <Card.Content>
                        <div style={{ height: "400px", overflowY: "auto" }}>
                            <Table celled sortable>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell
                                            onClick={() => handleSort('repoName')}
                                            style={{ cursor: "pointer", position: "sticky", top: 0, background: "white", zIndex: 1 }}
                                        >
                                            Repository {renderSortIcon('repoName')}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell
                                            onClick={() => handleSort('nodeVersion')}
                                            style={{ cursor: "pointer", position: "sticky", top: 0, background: "white", zIndex: 1 }}
                                        >
                                            Node Version {renderSortIcon('nodeVersion')}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell
                                            onClick={() => handleSort('reactVersion')}
                                            style={{ cursor: "pointer", position: "sticky", top: 0, background: "white", zIndex: 1 }}
                                        >
                                            React Version {renderSortIcon('reactVersion')}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell style={{ position: "sticky", top: 0, background: "white", zIndex: 1 }}>
                                            Node Status
                                        </Table.HeaderCell>
                                        <Table.HeaderCell style={{ position: "sticky", top: 0, background: "white", zIndex: 1 }}>
                                            React Status
                                        </Table.HeaderCell>
                                        <Table.HeaderCell style={{ position: "sticky", top: 0, background: "white", zIndex: 1 }}>
                                            Action
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {sortedRepositories.length > 0 ? (
                                        sortedRepositories.map((repo, index) => (
                                            <Table.Row key={index}>
                                                <Table.Cell>{repo.repoName}</Table.Cell>
                                                <Table.Cell>{formatVersion(repo.nodeVersion)}</Table.Cell>
                                                <Table.Cell>{formatVersion(repo.reactVersion)}</Table.Cell>
                                                <Table.Cell>{getVersionStatus(repo.outdated.node, 'Node')}</Table.Cell>
                                                <Table.Cell>{getVersionStatus(repo.outdated.react, 'React')}</Table.Cell>
                                                <Table.Cell>
                                                    <Button
                                                        primary
                                                        as="a"
                                                        href={`https://bitbucket.org/jeff_gosling/${repo.repoName}`}
                                                        target="_blank"
                                                    >
                                                        Open Repository
                                                    </Button>
                                                </Table.Cell>
                                            </Table.Row>
                                        ))
                                    ) : (
                                        <Table.Row>
                                            <Table.Cell colSpan={6} textAlign="center">
                                                No matching repositories found.
                                            </Table.Cell>
                                        </Table.Row>
                                    )}
                                </Table.Body>
                            </Table>
                        </div>
                    </Card.Content>
                </Card>
            </div>

            <Button
                primary
                onClick={() => setModalOpen(true)}
                style={{ margin: "20px 0" }}
            >
                Export and Email PDF Report
            </Button>

            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                size="tiny"
            >
                <Modal.Header>Export and Email PDF</Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Field error={!!emailError}>
                            <label>Email</label>
                            <div style={{ marginBottom: "10px" }}>
                                <label style={{ display: "block", marginBottom: "10px" }}>
                                    <input
                                        type="radio"
                                        checked={emailOption === "user"}
                                        onChange={() => {
                                            setEmailOption("user");
                                            setEmailError("");
                                        }}
                                        style={{ marginRight: "8px" }}
                                    />
                                    Send to my email ({props.user.email})
                                </label>
                            </div>
                            <div>
                                <label style={{ display: "block", marginBottom: "10px" }}>
                                    <input
                                        type="radio"
                                        checked={emailOption === "custom"}
                                        onChange={() => {
                                            setEmailOption("custom");
                                            setEmailError("");
                                        }}
                                        style={{ marginRight: "8px" }}
                                    />
                                    Send to a custom email
                                </label>
                            </div>
                            {emailOption === "custom" && (
                                <Form.Input
                                    placeholder="Enter custom email"
                                    value={customEmail}
                                    onChange={(e) => {
                                        setCustomEmail(e.target.value);
                                        setEmailError("");
                                    }}
                                    type="email"
                                    error={emailError ? {
                                        content: emailError,
                                        pointing: 'below',
                                    } : false}
                                    required
                                />
                            )}
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => {
                        setModalOpen(false);
                        setEmailError("");
                        setCustomEmail("");
                    }}>
                        Cancel
                    </Button>
                    <Button
                        primary
                        onClick={handleExportAndEmail}
                        disabled={emailOption === "custom" && (!customEmail || !!emailError)}
                    >
                        Send Email
                    </Button>
                </Modal.Actions>
            </Modal>
        </Template>
    );
};

function mapStateToProps(state: RootState) {
    return {
        user: state.auth.user,
    };
}

export default connect(mapStateToProps)(VersionTracking);