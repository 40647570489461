import {ActionType} from "../../model/actionTypes";
import {errorHandler} from "./auth";



interface insuranceObj {
    file: File,
    practice: string,
    patNum: string
}

export const uploadInsurance = (obj: insuranceObj) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp =  await api.post("openDental/insurance", obj, {isMultipart: true});
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        throw dispatch(errorHandler(err));
    }
};