import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {RootState} from "../../../model";
import {Account} from "../../../model/user";
import {Practice} from "../../../model/practice";
import {useActions} from "../../../redux/actions";
import * as spotActions from "../../../redux/actions/spot";
import {Grid, Button, Icon} from "semantic-ui-react";
import Template from "../../../components/Template";
import CommlogHistory from './CommlogHistory';
import PatientInformation from "./PatientInformation";
import PatientApptList from "./PatientApptList";
import PatientTreatList from "./PatientTreatList";
import FamilyView from "./FamilyView";
import OpenTreatPlan from "./OpenTreatPlan";
import {useParams} from 'react-router-dom';
import {resultDropDown} from "../constants";

interface Props {
    user: Account,
    practice: Practice
}

function SpotDetails(props: Props) {
    const {patientId, practice}:any = useParams();
    const spotAction = useActions(spotActions);
    const [state, setStates] = useState({result: [] as any[],lastExamResult:[] as any[], openTreatment:[] as any[]});

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        const result: any[] = await spotAction.fetchBrokenDetails({location: practice, PatNum: patientId});
        const lastExamResult: any[] = await spotAction.lastExamResult({location: practice, PatNum: patientId});
        const openTreatment: any[] = await spotAction.fetchTreatmentPlan({location: practice, PatNum: patientId});
        setStates({...state, result, lastExamResult,openTreatment})
    }

    const resultNewDropDown = resultDropDown.slice();
    resultNewDropDown.unshift({text: '--Select Result--', value: 'blank'}); // Add element on 0 index
    resultNewDropDown.splice(1, 2); // Remove element from array
    resultNewDropDown.splice(7, 2); // Remove element from

    const pagination = state.result && state.result[0] && state.result[0].pagination;

    return (
        <Template activeLink="spot details">
            <Grid>
                <Grid.Row>
                    <Grid.Column width={12}>
                        <h1>SUPPORT PATIENT OPPORTUNITIES TOOL - SPOT</h1>
                    </Grid.Column>
                    <Grid.Column width={4} className="text-right">
                        <Button secondary={true}>
                            <a href={`/spot/support-patient-opportunity-tool${window.location.search}`} style={{color: 'white'}}><Icon name="list"/> Spot Table</a>
                        </Button>
                        {
                            pagination && pagination.previous &&
                            <Button primary={true}>
                                <a href={`/spot/spot-details/${practice}/${pagination.previous}${window.location.search}`} style={{color: 'white'}}>
                                    <Icon name="chevron left"/> Previous</a>
                            </Button>
                        }
                        {
                            pagination && pagination.next &&
                            <Button primary={true} style={{color: 'white'}}>
                                <a href={`/spot/spot-details/${practice}/${pagination.next}${window.location.search}`} style={{color: 'white'}}>
                                    Next <Icon name="chevron right"/></a>
                            </Button>
                        }
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns="equal">
                    <Grid.Column>
                        <PatientInformation
                            resultOptions={resultNewDropDown}
                            result={state.result}
                            patientId={patientId}
                            practice={practice}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <PatientApptList patientId={patientId} practice={practice}/>
                    </Grid.Column>
                    <Grid.Column>
                        <PatientTreatList patientId={patientId} practice={practice}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns="equal">
                    <Grid.Column>
                        <FamilyView
                            resultOptions={resultNewDropDown}
                            result={state.result}
                            lastExamResult={state.lastExamResult}
                            openTreatment={state.openTreatment}
                            patientId={patientId}
                            practice={practice}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <CommlogHistory patientId={patientId} practice={practice}/>
                    </Grid.Column>
                    <Grid.Column>
                        <OpenTreatPlan patientId={patientId} practice={practice} result={state.result}/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Template>
    )
}

function mapStateToProps(state: RootState) {
    return {
        user: state.auth.user
    }
}

export default connect(mapStateToProps)(SpotDetails)
