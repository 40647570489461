import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Dropdown } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import { TEDEntry } from "../../model/tedCollections";
import moment from "moment";

interface TEDCollectionFormProps {
    initialValues: Partial<TEDEntry>;
    cancelForm: () => void;
    saveForm: (data: TEDEntry) => void;
    practices: any[];
    providers: any[];
}

const TEDCollectionForm = (props: TEDCollectionFormProps) => {
    const { initialValues, cancelForm, saveForm, practices, providers } = props;
    const [state, setState] = useState(initialValues);
    const [errors, setErrors] = useState({
        providerId: false,
        practiceId: false,
        startDate: false,
        endDate: false
    });

    const validateForm = (): boolean => {
        const newErrors = {
            providerId: !state.providerId,
            practiceId: !state.practiceId,
            startDate: !state.startDate,
            endDate: !state.endDate
        };
        setErrors(newErrors);
        return !Object.values(newErrors).some(error => error);
    };

    const handleSave = () => {
        if (validateForm()) {
            saveForm(state as TEDEntry);
        }
    };

    const handleDateChange = (date: Date | null, field: 'startDate' | 'endDate') => {
        setState(prev => ({
            ...prev,
            [field]: date
        }));
    };

    const handleDropdownChange = (field: string, value: any) => {
        const selectedItem = field === 'practiceId' 
            ? practices.find(p => p.value === value)
            : providers.find(p => p.value === value);

        setState(prev => ({
            ...prev,
            [`${field}`]: value,
        }));
    };

    return (
        <Modal
            onClose={cancelForm}
            open={true}
            size="small"
        >
            <Modal.Header>
                {initialValues.id ? "Edit" : "Create"} TED Collection
            </Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Field error={errors.practiceId}>
                        <label>Practice</label>
                        <Dropdown
                            placeholder="Select Practice"
                            fluid
                            selection
                            options={practices}
                            value={state.practiceId}
                            onChange={(_, data) => handleDropdownChange('practiceId', data.value)}
                        />
                    </Form.Field>

                    <Form.Field error={errors.providerId}>
                        <label>Provider</label>
                        <Dropdown
                            placeholder="Select Provider"
                            fluid
                            selection
                            options={providers}
                            value={state.providerId}
                            onChange={(_, data) => handleDropdownChange('providerId', data.value)}
                        />
                    </Form.Field>

                    <Form.Field error={errors.startDate}>
                        <label>Start Date</label>
                        <DatePicker
                            selected={state.startDate ? new Date(state.startDate) : null}
                            onChange={(date) => handleDateChange(date, 'startDate')}
                            dateFormat="MM/dd/yyyy"
                        />
                    </Form.Field>

                    <Form.Field error={errors.endDate}>
                        <label>End Date</label>
                        <DatePicker
                            selected={state.endDate ? new Date(state.endDate) : null}
                            onChange={(date) => handleDateChange(date, 'endDate')}
                            dateFormat="MM/dd/yyyy"
                            minDate={state.startDate ? new Date(state.startDate) : undefined}
                        />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button secondary onClick={cancelForm}>Cancel</Button>
                <Button primary onClick={handleSave}>Save</Button>
            </Modal.Actions>
        </Modal>
    );
};

export default TEDCollectionForm; 