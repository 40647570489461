import React from 'react';
import moment from 'moment'
import {
    TableRow,
    TableHeaderCell,
    TableHeader,
    TableCell,
    TableBody,
    Table,
} from 'semantic-ui-react'
const _ = require('underscore');

interface props {
    records: any
}

const AwsBounceTable = (props: props) => {
    const { records } = props;
    const headers = ['Source', 'Bounced Recipients', 'Destination', 'Sent', 'Subject', 'Reason']
    const cellStyle = {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '250px',
    };

    return (
        <>
            <Table striped compact='very'>
                <TableHeader>
                    <TableRow>
                        {
                            headers.map((header, key) => (<TableHeaderCell key={key}>{header}</TableHeaderCell>))
                        }
                    </TableRow>
                </TableHeader>

                <TableBody>
                    {
                        records && (records || []).map((r: any, index: number) =>
                            <TableRow key={index}>
                                <TableCell >{r.mail.source}</TableCell>
                                <TableCell title={r.bounce.bouncedRecipients.map((o: any) => o.emailAddress).join()} style={cellStyle}>
                                    {r.bounce.bouncedRecipients.map((o: any) => o.emailAddress).join()}
                                </TableCell>
                                <TableCell title={r.mail.destination.join()} style={cellStyle} >{r.mail.destination.join()}</TableCell>
                                <TableCell >{moment(r.mail.timestamp).format('YYYY-MM-DD @HH:mm:ss')}</TableCell>
                                <TableCell >{r.mail.commonHeaders.subject}</TableCell>
                                <TableCell >{r.bounce.bouncedRecipients[0]?.diagnosticCode}</TableCell>
                            </TableRow>
                        )
                    }
                </TableBody>
            </Table>
        </>

    )
}

export default AwsBounceTable
